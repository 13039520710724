import axios from 'axios';

const instance = axios.create({
  //  baseURL: "https://shipping.cordiacesolutions.com/shipping-api/public/api",
  // baseURL: "http://localhost:3000/backend/public/api",
  baseURL: "https://sts.keyshipper.com/backend/public/api",
  // baseURL: "https://sts.keyshipper.com/backend/public/api",
  headers: { Accept: 'application/json' }, 
});

export default instance; 
