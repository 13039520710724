import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// reactstrap components
import {
  Card,
  Table,
  Row,
  Col,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useLocation } from "react-router-dom";
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import InputField from "./Fields/InputField";
import Dropdown from "./Fields/Dropdown";
import InputDate from "./Fields/InputDate";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function createData(id, jobType, project, party, status, action) {
  return {
    id,
    jobType,
    project,
    party,
    status,
    action,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'shipper',
    numeric: false,
    disablePadding: false,
    label: 'Shipper',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: true,
    label: 'Party',
  },
  {
    id: 'consignee',
    numeric: false,
    disablePadding: false,
    label: 'Consignee',
  },
  {
    id: 'order_number',
    numeric: false,
    disablePadding: false,
    label: 'Order Number',
  },
  {
    id: 'delivery_date',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
  },
  {
    id: 'collection_date',
    numeric: false,
    disablePadding: false,
    label: 'Collection Date',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

const headCellsEc = [
  {
    id: 'shipper',
    numeric: false,
    disablePadding: false,
    label: 'Shipper',
  },
  {
    id: 'airwaybill',
    numeric: false,
    disablePadding: true,
    label: 'AirwayBill No',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'date',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country of origin',
  },
];

const headCellsDBP = [
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Form Category',
  },
  {
    id: 'collection_date',
    numeric: false,
    disablePadding: true,
    label: 'Collection Date',
  },
  // {
  //   id: 'date',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'date',
  // },
  // {
  //   id: 'collection',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Collection',
  // },
  // {
  //   id: 'delivery',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Delivery',
  // },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

function EnhancedTableHeadEc(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCellsEc.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}
function EnhancedTableHeadDBP(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCellsDBP.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableHeadEc.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHeadDBP.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbarEc = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbarEc.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function JobDetails() {

  const today = new Date();
  const defaultDate = today.toLocaleDateString('en-CA');
  // const classes = useStyles();
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
 
  const location = useLocation();
// console.log('details',location);
const urlParams = new URLSearchParams(window.location.search);
const detail = JSON.parse(urlParams.get('detail'));
const detailpage = urlParams.get('page');
const detailTab = urlParams.get('tab');
// console.log('detail',detail);

  // console.log(location);
  const [formError, setFormError] = useState(null);

//Collection Devlivery Note
const [dateOfIssue, setdateOfIssue] = useState(defaultDate);
const [shipperRef, setShipperRef] = useState([]); 
// const [category, setCategory] = useState([]); 

 const [collectionShipper, setCollectionShipper] = useState(null);
 const [collectionConsignee, setCollectionConsignee] = useState(null);
const [hawbhcvbNo, setHawbhcvbNo] = useState('');
const [descriptionOfGoods, setDescriptionOfGoods] = useState('');
const [prefixes, setPrefixes] = useState([]);
const [prefix, setPrefix] = useState(null);
const [mawbmcvbNo, setMawbmcvbNo] = useState('');
const [shipperAddress, setshipperAddress] = useState('');
const [jobRef, setJobRef] = useState('');
const [jobCode, setJobCode] = useState('');

const [collectionDeliveryNotes, setCollectionDeliveryNotes] = useState([]);
const [collectionDeliveryId, setcollectionDeliveryId] = useState(0);
// const [weight, setWeight] = useState('');

const getAirlinePrefixes = () => {
  axiosCtx.instance.get(`airline_prefix`)
      .then((response) => {
          setPrefixes(response.data.data);
      })
      .catch((error) => {
      });
};

const prefixHandleChange = (event) => {
  setPrefix(event.target.value);
};
const shipperRefHandleChange = (event) => {
  setShipperRef(event.target.value);
};
// const categoryHandleChange = (event) => {
//   setCategory(event.target.value);
// };
const jobRefHandleChange = (event) => {
  setJobRef(event.target.value);
};
const descriptionOfGoodsHandleChange = (event) => {
  setDescriptionOfGoods(event.target.value);
};
// const shipperHandleChange = (event) => {
//   setShipper(event.target.value);
// };

const collectionConsigneeHandleChange = (event) => {
  setCollectionConsignee(event.target.value);
};

const hawbNoHandleChange = (event) => {
  setHawbhcvbNo(event.target.value);
};
const dateOfIssueChange = (event) => {
  setdateOfIssue(event.target.value);
};

const shipperAddressChange = (event) => {
  setshipperAddress(event.target.value);
};

const CollectionDeliveryDeleteSubmitHandler = () => {
  setLoading(true);
  if (deleteCollectionDeliveryId) {
    axiosCtx.instance.delete(`collectionDeliveryNote/` + deleteCollectionDeliveryId,)
      .then((response) => {
        setLoading(false);
        setDeleteMessageCollectionDelivery('');
        getCollectionDeliveryNote(response.data.data.job_id);
      
        setShowDeleteCollectionDelivery(false);
      })
      .catch((error) => {
        setLoading(false);
        setDeleteMessageAirwayBill(error.response.data.errors);
      });
  }
};

  //Job
  const [job, setJob] = useState([]);
  const [job_id, setJobid] = useState('');
  const [status, setStatus] = useState([]);
  const [jobtype, setJobtype] = useState([]);
  const [project, setProject] = useState([]);
  const [party, setParty] = useState([]);
  const [port, setPort] = useState([]);

  const [dateOfExecution, setDateOfExecution] = useState('');
  const [comments, setComments] = useState('');
  const handleBackClick = () => {
    // history.goBack(); // This will go back to the previous page in the browser history
    history.push('/job-list');
    // localStorage.removeItem(localStorageKey);
    // https://sts.cordiacesolutions.com/job-list
  };
  const jobUpdateHandler = () => {
    // event.preventDefault();
    // setFormError(null);
    setLoading(true);

    // Call login API
    const formData = new FormData();
    const params = {
      status_id: (status) ? status.id : '',
      party_id: (party) ? party.id : '',
      port_id: (port) ? port.id : '',
      date_of_execution: dateOfExecution,
      comments: comments,
    };

    axiosCtx.instance.put(`job/` + job_id, params)
      .then((response) => {
        setLoading(false);

        setJob(response.data.data);
        setStatus(response.data.data.status);
        setParty(response.data.data.party);
        setPort(response.data.data.port);
        setDateOfExecution(response.data.data.date_of_execution);
        setComments(response.data.data.comments);
        // history.push({
        //   pathname: 'job-details',
        //   state: { detail: response.data.data, page: 'index' }
        // });
        const jobDetailsUrl = `/job-details`;

        // Create a query string with the state information
        const queryString = `?detail=${encodeURIComponent(JSON.stringify(response.data.data))}&page=index`;
      
        // Open the new tab with the URL containing query parameters
        window.location.href = jobDetailsUrl + queryString;
      })
      .catch((error) => {

        // setFormError(error.response.data);
        setLoading(false);
      });
  };

  const getPorts = () => {
    axiosCtx.instance.get(`port`)
      .then((response) => {
        setPorts(response.data.data);
        console.log('ports',response.data.data)
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getCostDescriptions = () => {
    axiosCtx.instance.get(`cost-description`)
      .then((response) => {
        setCostDescriptions(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getCostTypes = () => {
    axiosCtx.instance.get(`cost-type`)
      .then((response) => {
        setCostTypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const setPodForms = (data) => {
    let newFormValues = [...podFormValues];
    newFormValues[0]['job_number'] = data.prefix.prefix + data.mawb_mcbv_no;
    newFormValues[0]['description'] = data.nature_of_goods;
    newFormValues[0]['quantity'] = data.weight;
    newFormValues[0]['piece'] = data.piece;
    setPodTotalPackage(data.weight);
    setPodTotalPiece(data.piece);
  };

  const getAirwayBills = (job_id) => {
    setAirBillLoading(true);
    axiosCtx.instance.get(`airway-bill/job/` + job_id)
      .then((response) => {
        setAirwayBills(response.data.data);
        setPodForms(response.data.data[0]);
        setPodBlAwb(response.data.data[0]);
        setAirBillLoading(false);

      })
      .catch((error) => {
        // setFormError(error.response.data);
        setAirBillLoading(false);
      });
  };
  const getCollectionDeliveryNote = (job_id) => {
    axiosCtx.instance.get(`collectionDeliveryNote/job/` + job_id)
      .then((response) => {
        console.log('collections',response.data)
        setCollectionDeliveryNotes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getAirwayBillsLocalOrder = (job_id) => {
    axiosCtx.instance.get(`cost/airwaybill-localorder/` + job_id)
      .then((response) => {
        setAirwayBillsLocalOrders(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getCosts = (job_id) => {
    setCostLoading(true);
    axiosCtx.instance.get(`cost/job/` + job_id)
      .then((response) => {
        setCosts(response.data.data);
        setCostLoading(false);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        setCostLoading(false);
      });
  };

  const getStatues = () => {
    axiosCtx.instance.get(`status`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  // const getProjectsBySubType = (sub_type_id) =>{
  //   axiosCtx.instance.get(`project/sub-type/`+sub_type_id)
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setProjects(response.data.data);
  //     })
  //     .catch((error) => {
  //     });
  // };

  const getProjects = () => {
    axiosCtx.instance.get(`project`)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getSubtypes = () => {
    axiosCtx.instance.get(`sub-type`)
      .then((response) => {

        setSubtypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobs = () => {
    axiosCtx.instance.get(`job`)
      .then((response) => {
        setJob(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getCurrencies = () => {
    axiosCtx.instance.get(`currency`)
      .then((response) => {
        setCurrencies(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobtypes = () => {
    axiosCtx.instance.get(`job-type`)
      .then((response) => {
        setJobtypes(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  //Airwaybill
  const [projects, setProjects] = useState([]);
  const [jobtypes, setJobtypes] = useState([]);
  const [parties, setParties] = useState([]);
  const [ports, setPorts] = useState([]);
  const [airwaybillsLocalOrders, setAirwayBillsLocalOrders] = useState([]);
  
  const [airwaybills, setAirwayBills] = useState([]);
  const [costs, setCosts] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [localOrders, setLocalOrders] = useState([]);

  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [piece, setPiece] = useState('');
  const [weight, setWeight] = useState('');
  const [volume, setVolume] = useState('');
  const [ntrGoods, setNtrGoods] = useState('');
  const [pickupLoc, setPickupLoc] = useState('');
  const [primaryShc, setPrimaryShc] = useState('');
  const [awbType, setAwbType] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [mawbNo, setMawbNo] = useState('');

  const [showAirwayBill, setShowAirwayBill] = useState(false);
  const handleCloseAirwayBill = () => setShowAirwayBill(false);
  const onCreateAirwaybillClick = () => setShowAirwayBill(true);

  const statusHandleChange = (event) => {
    setStatus(event.target.value);
  };

  const jobtypeHandleChange = (event) => {
    setJobtype(event.target.value);
  };

  const projectHandleChange = (event) => {
    setProject(event.target.value);
  };

  const partyHandleChange = (event) => {
    setParty(event.target.value);
  };

  const portHandleChange = (event) => {
    setPort(event.target.value);
  };

  const originHandleChange = (event) => {
    setOrigin(event.target.value);
  };

  const destinationHandleChange = (event) => {
    setDestination(event.target.value);
  };

  const pieceHandleChange = (event) => {
    setPiece(event.target.value);
  };

  const weightHandleChange = (event) => {
    setWeight(event.target.value);
  };

  const volumeHandleChange = (event) => {
    setVolume(event.target.value);
  };

  const ntrGoodsHandleChange = (event) => {
    setNtrGoods(event.target.value);
  };

  const pickupLocHandleChange = (event) => {
    setPickupLoc(event.target.value);
  };

  const primaryShcHandleChange = (event) => {
    setPrimaryShc(event.target.value);
  };

  const awbTypeHandleChange = (event) => {
    setAwbType(event.target.value);
  };

  const documentTypeHandleChange = (event) => {
    setDocumentType(event.target.value);
  };

  const mawbNoHandleChange = (event) => {
    setMawbNo(event.target.value);
  };

  //Local Order
  const [showLocalOrder, setLocalOrder] = useState(false);
  const handleCloseLocalOrder = () => {
    setLocalOrder(false);
    setLocalOrderId('');
    setFormError('');
    setShipper(null);
    setPartyLocal(null);
    setConsignee(null);
    setOrderNumber('');
    // setProjectLocal(null);
    setPcs('');
    setWeightLocal('');
    setDimension('');
    setDeliveryLocation('');
    setPortLocal(null);
    setLoc(null);
    setRack(null);
    setTotalPackage('');
    setPackageInDate('');
    setTotalDimsPieces('');
    setVolumeDims('');
    setVolumetricWeightDims('');
    setVolume('');
    setDimsFormValues([{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }]);
  }
  const onCreateLocalOrder = () => setLocalOrder(true);

  const [shipper, setShipper] = useState(null);
  const [partyLocal, setPartyLocal] = useState(null);
  const [consignee, setConsignee] = useState(null);
  const [orderNumber, setOrderNumber] = useState('');
  const [projectLocal, setProjectLocal] = useState(null);
  const [pcs, setPcs] = useState('');
  const [weightLocal, setWeightLocal] = useState('');
  const [dimension, setDimension] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('');
  const [portLocal, setPortLocal] = useState(null);
  const [collectionDate, setCollectionDate] = useState(defaultDate);
  const [deliveryDate, setDeliveryDate] = useState(defaultDate);

  const [airwaybill, setAirwaybill] = useState('');
  const [airwaybillLocalOrder, setAirwaybillLocalOrder] = useState(null);
  const [costDescription, setCostDescription] = useState(null);
  const [costType, setCostType] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [currencies, setCurrencies] = useState('');
  const [costId, setCostId] = useState('');

  const [localOrderId, setLocalOrderId] = useState('');

  const [locations, setLocations] = useState([]);
  const [loc, setLoc] = useState(null);
  const [racks, setRacks] = useState([]);
  const [rack, setRack] = useState(null);
  const [totalPackage, setTotalPackage] = useState('');
  const [packageInDate, setPackageInDate] = useState(defaultDate);

  const [totalDimsPieces, setTotalDimsPieces] = useState('');
  const [volumeDims, setVolumeDims] = useState('');
  const [volumetricWeightDims, setVolumetricWeightDims] = useState('');

  const [dimsFormValues, setDimsFormValues] = useState([{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }])

  const addDimsField = () => {
    setDimsFormValues([...dimsFormValues, { piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }])
  };

  let removeDimsField = (i, e) => {
    let newFormValues = [...dimsFormValues];
    newFormValues.splice(i, 1);
    setDimsFormValues(newFormValues)

    const pieceTotal = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.piece) && currentValue.piece > 0)) ? total = parseInt(total) + parseInt(currentValue.piece) : total = total, 0);
    setTotalDimsPieces(pieceTotal);
    setPiece(pieceTotal);
    setTotalPackage(pieceTotal);

    const totVol = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volume) && currentValue.volume > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volume) : total = total, 0);
    setVolume(totVol);

    const totVolMet = newFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volumetric_weight) && currentValue.volumetric_weight > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volumetric_weight) : total = total, 0);
    setVolumetricWeightDims(Math.round(totVolMet));

  }

  const dimsFieldsHandleChange = (i, e) => {
    let formValues = [...dimsFormValues];
    formValues[i][e.target.name] = e.target.value;
    setDimsFormValues(formValues);
  }
  const dimsFieldsFocusOut = (i, e) => {
    const pieceTotal = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.piece) && currentValue.piece > 0)) ? total = parseInt(total) + parseInt(currentValue.piece) : total = total, 0);
    setTotalDimsPieces(pieceTotal);
    setPiece(pieceTotal);
    setTotalPackage(pieceTotal);

    let formValues = [...dimsFormValues];
    let volMetricWe = (formValues[i]["piece"] * formValues[i]["length"] * formValues[i]["height"] * formValues[i]["width"]) / 6000;
    let vol = volMetricWe.toFixed(2) / 166.67;
    formValues[i]["volume"] = vol.toFixed(2);
    formValues[i]["volumetric_weight"] = volMetricWe.toFixed(2);
    setDimsFormValues(formValues);

    const totVol = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volume) && currentValue.volume > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volume) : total = total, 0);
    setVolumeDims(totVol.toFixed(2));
    setVolume(totVol.toFixed(2));

    const totVolMet = dimsFormValues.reduce((total, currentValue) => ((!isNaN(currentValue.volumetric_weight) && currentValue.volumetric_weight > 0)) ? total = parseFloat(total) + parseFloat(currentValue.volumetric_weight) : total = total, 0);
    setVolumetricWeightDims(Math.round(totVolMet));

  }

  const onAirwayBillDeleteHandler = (item) => {
    setDeleteAirwayBillId(item);
    handleShowDeleteAirwayBill();
  };

  const onCollectionDeliveryDeleteHandler = (item) => {
    setDeleteCollectionDeliveryId(item);
    handleShowDeleteCollectionDelivery();
  };

  const onAirwayBillAdd = (item) => {
    history.push({
      pathname: `airwaybill`,
      state: {
        data: {
          job_id: item,
        },
      }
    });
  }

  const onAirwayBillEditHandler = (item) => {
    history.push({
      pathname: `airwaybill/${item.id}`,
      state: {
        data: {
          id: item.id,
          job_id: item.job_id,
        },
      }
    });
  }

  const onAirwayBillPrintHandler = (item) => {
    history.push({
      pathname: `airwaybill-pdf/${item.id}`,
      state: {
        data: {
          id: item.id,
          job_id: item.job_id,
        },
      }
    });
  }

  const AirwayBillDeleteSubmitHandler = () => {
    setLoading(true);
    if (deleteAirwayBillId) {
      axiosCtx.instance.delete(`airway-bill/` + deleteAirwayBillId,)
        .then((response) => {
          setLoading(false);
          setDeleteMessageAirwayBill('');
          getAirwayBills(response.data.data.job_id);
          getAirwayBillsLocalOrder(response.data.data.job_id);
          setShowDeleteAirwayBill(false);
        })
        .catch((error) => {
          setLoading(false);
          setDeleteMessageAirwayBill(error.response.data.errors);
        });
    }
  };

  const onLocalOrderDeleteHandler = (item) => {
    setDeleteLocalOrderId(item);
    handleShowDeleteLocalOrder();
  };

  const LocalOrderDeleteSubmitHandler = () => {
    setLoading(true);
    if (deleteLocalOrderId) {
      console.log(deleteLocalOrderId);
      axiosCtx.instance.delete(`local-order/` + deleteLocalOrderId,)
        .then((response) => {
          setLoading(false);
          setDeleteMessageLocalOrder('');
          getLocalOrderByJob(response.data.data.job_id);
          getAirwayBillsLocalOrder(response.data.data.job_id);
          setShowDeleteLocalOrder(false);
        })
        .catch((error) => {
          setLoading(false);
          setDeleteMessageLocalOrder(error.response.data.errors);
        });
    }
  };

  const [showDeleteLocalOrder, setShowDeleteLocalOrder] = useState(false);
  const [deleteMessageLocalOrder, setDeleteMessageLocalOrder] = useState('');
  const [deleteLocalOrderId, setDeleteLocalOrderId] = useState('');

  const handleCloseDeleteLocalOrder = () => {
    setShowDeleteLocalOrder(false);
    setDeleteMessageLocalOrder('');
    setDeleteLocalOrderId('');
  }
  const handleShowDeleteLocalOrder = () => setShowDeleteLocalOrder(true);

  const [showDeleteAirwayBill, setShowDeleteAirwayBill] = useState(false);
  const [deleteMessageAirwayBill, setDeleteMessageAirwayBill] = useState('');
  const [deleteAirwayBillId, setDeleteAirwayBillId] = useState('');

  const [showDeleteCollectionDelivery, setShowDeleteCollectionDelivery] = useState(false);
  const [deleteMessageCollectionDelivery, setDeleteMessageCollectionDelivery] = useState('');
  const [deleteCollectionDeliveryId, setDeleteCollectionDeliveryId] = useState('');

  const handleCloseDeleteAirwayBill = () => {
    setShowDeleteAirwayBill(false);
    setDeleteMessageAirwayBill('');
    setDeleteAirwayBillId('');
  }
  const handleShowDeleteAirwayBill = () => setShowDeleteAirwayBill(true);

  const handleCloseDeleteCollectionDelivery = () => {
    setShowDeleteCollectionDelivery(false);
    setDeleteMessageCollectionDelivery('');
    setDeleteCollectionDeliveryId('');
  }
  const handleShowDeleteCollectionDelivery = () => setShowDeleteCollectionDelivery(true);

  const getLocalOrderByJob = (job_id) => {
    axiosCtx.instance.get(`local-order/job/` + job_id)
      .then((response) => {
        setLocalOrders(response.data.data);

      })
      .catch((error) => {
      });
  };

  const getLocations = () => {
    axiosCtx.instance.get(`location`)
      .then((response) => {
        setLocations(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getRacks = () => {
    axiosCtx.instance.get(`rack`)
      .then((response) => {
        setRacks(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const onLocalEditPress = (data) => {
    setShipper(data.shipper_id);
    setPartyLocal(data.party_id);
    setConsignee(data.consignee_id);
    setOrderNumber(data.order_number);
    setProjectLocal(data.project_id);
    setPcs(data.pcs);
    setWeightLocal(data.weight);
    setDimension(data.dimension);
    setDeliveryLocation(data.delivery_location);
    setPortLocal(data.port_id);
    setCollectionDate(data.collection_date);
    setDeliveryDate(data.delivery_date);
    setLocalOrder(true);
    setLocalOrderId(data.id);
    setLoc(data.warehouse.location ? data.warehouse.location : null);
    setRack(data.warehouse.rack ? data.warehouse.rack : null);
    setTotalPackage(data.warehouse.total_packages ? data.warehouse.total_packages : '');
    setPackageInDate(data.warehouse.packages_in_date ? data.warehouse.packages_in_date : '');

    setTotalDimsPieces(data.dims_total_piece ? data.dims_total_piece : '');
    setVolumeDims(data.dims_total_volume ? data.dims_total_volume : '');
    setVolumetricWeightDims(data.dims_total_volumetric_weight ? data.dims_total_volumetric_weight : '');
    setVolume(data.dimension);
    setDimsFormValues((data.dims_fileds) ? JSON.parse(data.dims_fileds) : [{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }]);
  }

  const handleSubmitLocalOrder = () => {
    setLoading(true);

    // Call login API
    const formData = new FormData();
    const params = {
      job_id: job_id,
      party_id: (partyLocal) ? partyLocal.id : '',
      shipper_id: (shipper) ? shipper.id : '',
      consignee_id: (consignee) ? consignee.id : '',
      order_number: (orderNumber) ? orderNumber : '',
      project_id: (projectLocal) ? projectLocal.id : '',
      pcs: pcs,
      weight: weightLocal,
      dimension: volumeDims,
      delivery_location: deliveryLocation,
      port_id: (portLocal) ? portLocal.id : '',
      collection_date: collectionDate,
      delivery_date: deliveryDate,
      id: localOrderId,
      warehouse_loc: (loc) ? loc.id : '',
      rack: (rack) ? rack.id : '',
      total_packages: totalPackage,
      packages_in_date: packageInDate,
      dims_total_piece: totalDimsPieces,
      dims_total_volume: volumeDims,
      dims_total_volumetric_weight: volumetricWeightDims,
      dims_fileds: JSON.stringify(dimsFormValues),
    };

    if (localOrderId) {
      axiosCtx.instance.put(`local-order/` + localOrderId, params)
        .then((response) => {
          getLocalOrderByJob(response.data.data.job_id);
          getAirwayBillsLocalOrder(response.data.data.job_id);
          setShipper(null);
          setPartyLocal(null);
          setConsignee(null);
          setOrderNumber('');
          setProjectLocal(null);
          setPcs('');
          setWeightLocal('');
          setDimension('');
          setDeliveryLocation('');
          setPortLocal(null);
          setLocalOrderId('');
          setLocalOrder(false);
          setFormError('');
          setLoc(null);
          setRack(null);
          setTotalPackage('');
          setPackageInDate('');
          setLoading(false);
          setTotalDimsPieces('');
          setVolumeDims('');
          setVolumetricWeightDims('');
          setVolume('');
          setDimsFormValues([{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }]);
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error.response.data);
        });
    } else {
      axiosCtx.instance.post(`local-order`, params)
        .then((response) => {
          console.log(response.data.data.job_id);
          getLocalOrderByJob(response.data.data.job_id);
          getAirwayBillsLocalOrder(response.data.data.job_id);
          setShipper(null);
          setPartyLocal(null);
          setConsignee(null);
          setOrderNumber('');
          setProjectLocal(null);
          setPcs('');
          setWeightLocal('');
          setDimension('');
          setDeliveryLocation('');
          setPortLocal(null);
          setCollectionDate('');
          setDeliveryDate('');
          setLocalOrderId('');
          setLocalOrder(false);
          setFormError('');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error.response.data);
        });
    }
  }

  const totalPackageHandleChange = (event) => {
    setTotalPackage(event.target.value);
  };

  const packageInDateHandleChange = (event) => {
    setPackageInDate(event.target.value);
  };

  const shipperHandleChange = (event) => {
    setShipper(event.target.value);
  };

  const partyLocalHandleChange = (event) => {
    setPartyLocal(event.target.value);
  };

  const consigneeHandleChange = (event) => {
    setConsignee(event.target.value);
  };
  const orderNumberHandleChange = (event) => {
    setOrderNumber(event.target.value);
  };
  const projectLocalHandleChange = (event) => {
    setProjectLocal(event.target.value);
  };

  const pcsHandleChange = (event) => {
    setPcs(event.target.value);
    setTotalPackage(event.target.value)
  };

  const weightLocalHandleChange = (event) => {
    setWeightLocal(event.target.value);
  };

  const dimensionHandleChange = (event) => {
    setDimension(event.target.value);
  };

  const deliveryLocationHandleChange = (event) => {
    setDeliveryLocation(event.target.value);
  };

  const portLocalHandleChange = (event) => {
    setPortLocal(event.target.value);
  };

  const collectionDateHandleChange = (event) => {
    setCollectionDate(event.target.value);
  };

  const deliveryDateHandleChange = (event) => {
    setDeliveryDate(event.target.value);
  };

  //Cost
  const [costDescriptions, setCostDescriptions] = useState([]);
  const [costTypes, setCostTypes] = useState([]);
  const [costFormName, setCostFormName] = useState('Add');

  const [costAmount, setCostAmount] = useState(0);
  const [costVat, setCostVat] = useState(0);
  const [costTotal, setCostTotal] = useState(0);
  const [costCurrency, setCostCurrency] = useState(null);
  const [costUnit, setCostUnit] = useState('');
  const [costDate, setCostDate] = useState('');
  const [internationalVat, setInternationalVat] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');

  const [revenueAmount, setRevenueAmount] = useState(0);
  const [revenueVat, setRevenueVat] = useState(0);
  const [revenueTotal, setRevenueTotal] = useState(0);
  const [revenueCurrency, setRevenueCurrency] = useState(null);

  const onPodDeleteHandler = (item) => {
    handleShowDeletePod();
  };

  const PodDeleteSubmitHandler = () => {
    setLoading(true);
    if (podId) {
      axiosCtx.instance.delete(`pod/` + podId,)
        .then((response) => {
          setLoading(false);
          getPod(job_id);
          setDeleteMessagePod('');
          setShowDeletePod(false);
        })
        .catch((error) => {
          setLoading(false);
          setDeleteMessagePod(error.response.data.errors);
        });
    }
  };

  const [showDeletePod, setShowDeletePod] = useState(false);
  const [deleteMessagePod, setDeleteMessagePod] = useState('');

  const handleCloseDeletePod = () => {
    setShowDeletePod(false);
    setDeleteMessagePod('');
  }
  const handleShowDeletePod = () => setShowDeletePod(true);

  const costDateHandleChange = (event) => {
    setCostDate(event.target.value);
  }
  const internationalVatHandleChange = (event) => {
    setInternationalVat(event.target.checked);
  }

  const referenceNumberHandleChange = (event) => {
    setReferenceNumber(event.target.value);
  }

  const costUnitHandleChange = (event) => {
    setCostUnit(event.target.value);
    if (event.target.value > 0) {
      let costper = costAmount * (costVat / 100);
      let costtot = parseFloat(costper) + parseFloat(costAmount);
      let cstTotal = costtot * event.target.value;

      let revper = revenueAmount * (revenueVat / 100);
      let revtot = parseFloat(revper) + parseFloat(revenueAmount);
      let rvnTotal = revtot * event.target.value;

      if (!isNaN(cstTotal)) {
        setCostTotal(cstTotal);
      }
      if (!isNaN(rvnTotal)) {
        setRevenueTotal(rvnTotal);
      }

    }
    else {
      let costper = costAmount * (costVat / 100);
      let costtot = parseFloat(costper) + parseFloat(costAmount);

      let revper = revenueAmount * (revenueVat / 100);
      let revtot = parseFloat(revper) + parseFloat(revenueAmount);

      if (!isNaN(costtot)) {
        setCostTotal(costtot);
      }
      if (!isNaN(revtot)) {
        setRevenueTotal(revtot);
      }

    }
  };

  const revenueAmountHandleChange = (event) => {
    setRevenueAmount(event.target.value);
  };

  const revenueVatHandleChange = (event) => {
    setRevenueVat(event.target.value);
  };

  const revenueTotalHandleChange = (event) => {
    setRevenueTotal(event.target.value);
  };

  const revenueCurrencyHandleChange = (event) => {
    setRevenueCurrency(event.target.value);
  };

  const costAmountHandleChange = (event) => {
    setCostAmount(event.target.value);
  };

  const costVatHandleChange = (event) => {
    setCostVat(event.target.value);
  };

  const costVatBlur = (event) => {
    let costper = costAmount * (costVat / 100);
    let costtot = parseFloat(costper) + parseFloat(costAmount);
    if (costUnit > 0) {
      setCostTotal(costtot * costUnit);
    }
    else {
      setCostTotal(costtot);
    }

  }

  const revenueVatBlur = (event) => {
    let revper = revenueAmount * (revenueVat / 100);
    let revtot = parseFloat(revper) + parseFloat(revenueAmount);
    if (costUnit > 0) {
      setRevenueTotal(revtot * costUnit);
    }
    else {
      setRevenueTotal(revtot);
    }

  }
  const DeliveryNoteShow = () => { setFormError(null); setShowDeliveryNote(true); } 
  const BookingConfirmationShow = () => { setFormError(null); setShowBookingConfirmation(true); }
  const PreAlertShow = () => { setFormError(null); setShowPreAlert(true); }
  const volumeShow = () => { setFormError(null); setShowVolume(true); }
  const volumeAddcostShow = () => { setFormError(null); setShowAddCostVolume(true); }
  const volumeDimsShow = () => { setShowVolumeDims(true); }
  const volumeDimsClose = () => {
    setShowVolumeDims(false);
  }
  const volumeClose = () => {
    setCostFormName('Add');
    setCostAmount('');
    setCostUnit('');
    setCostVat('');
    setCostTotal('');
    setCostCurrency(null);
    setAirwaybillLocalOrder(null);
    setCostDescription(null);
    setCostType(null);
    setSupplier(null);

    setRevenueCurrency(null);
    setRevenueAmount('');
    setRevenueVat('');
    setRevenueTotal('');
    setCostId('');
    setShowVolume(false);
  }
  const volumeAddCostClose = () => {
    setCostFormName('Add');
    setCostAmount('');
    setCostUnit('');
    setCostVat('');
    setCostTotal('');
    setCostCurrency(null);
    setAirwaybillLocalOrder(null);
    setCostDescription(null);
    setCostType(null);
    setSupplier(null);

    setRevenueCurrency(null);
    setRevenueAmount('');
    setRevenueVat('');
    setRevenueTotal('');
    setCostId('');
    setShowAddCostVolume(false);
  }
  const DeliveryNoteClose = () => {
    setCostFormName('Add');
   
    setShowDeliveryNote(false);
  }
  const BookingConfirmationClose = () => {
    setCostFormName('Add');
   
    setShowBookingConfirmation(false);
  }
  const PreAlertClose = () => {
    setCostFormName('Add');
   
    setShowPreAlert(false);
  }
  const [ShowDeliveryNote, setShowDeliveryNote] = useState(false);
  const [ShowBookingConfirmation, setShowBookingConfirmation] = useState(false);
  const [ShowPreAlert, setShowPreAlert] = useState(false);
  const [ShowVolume, setShowVolume] = useState(false);
  const [ShowAddCostVolume, setShowAddCostVolume] = useState(false);
  const [ShowVolumeDims, setShowVolumeDims] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCostEditPress = (data, item) => {
    // setAirwaybill(data.airway_bill.id);
    setCostFormName('Edit');
    setCostAmount(data.cost_amount);
    setCostUnit(data.cost_unit);
    setCostVat(data.cost_vat);
    setCostTotal(data.cost_total);
    if (data.cost_currency_id != null) {
      setCostCurrency(data.cost_currency_id);
    }

    setAirwaybillLocalOrder(item.orderno);
    setCostDescription(data.cost_description);
    setCostType(data.cost_type);
    setSupplier(data.supplier);

    if (data.revenue_currency != null) {
      setRevenueCurrency(data.revenue_currency);
    }

    setRevenueAmount(data.revenue_amount);
    setRevenueVat(data.revenue_vat);
    setRevenueTotal(data.revenue_total);
    setCostDate(data.cost_date);
    setInternationalVat(data.international_vat);
    setReferenceNumber(data.reference_number);
    setCostId(data.id);
    setFormError(null);
    setShowVolume(true);

  }

  const onDeleteCost = () => {
    setFormError(null);
    setLoading(true);
    axiosCtx.instance.delete(`cost/` + costId)
      .then((response) => {
        setLoading(false);
        setShowVolume(false);
        getCosts(response.data.data.job_id);

        setCostUnit('');

        setCostAmount('');
        setCostVat('');
        setCostTotal('');
        setCostCurrency(null);
        setAirwaybillLocalOrder(null);
        setCostDescription(null);
        setCostType(null);
        setSupplier(null);

        setRevenueCurrency(null);
        setRevenueAmount('');
        setRevenueVat('');
        setRevenueTotal('');
        setCostDate('');
        setInternationalVat(false);
        setReferenceNumber('');

        setCostId('');
      })
      .catch((error) => {
        setLoading(false);
        setFormError(error.response.data);
        // setLoading(false);
      });
  }
  const submitCostEntry = async () => {
    setFormError(null);
    setLoading(true);
    try {
      const payload = {
        order_number: (airwaybillLocalOrder) ? airwaybillLocalOrder.order_number : '', // Ensure you have this state
        cost_entries: costFields, // Send all dynamic cost entries
        job_id: job_id,
      };
  console.log(payload);
      axiosCtx.instance.post(`cost-store`, payload)
        .then((response) => {
          console.log(response.data);
          setShowAddCostVolume(false);
          getCosts(response.data.data[0].job_id);

          setCostUnit('');
          setCostAmount('');
          setCostVat('');
          setCostTotal('');
          setCostCurrency(null);
          setAirwaybillLocalOrder(null);
          setCostDescription(null);
          setCostType(null);
          setSupplier(null);

          setRevenueCurrency(null);
          setRevenueAmount('');
          setRevenueVat('');
          setRevenueTotal('');
          setCostDate('');
          setInternationalVat(false);
          setReferenceNumber('');
          setLoading(false);
        })
        .catch((error) => {
          setFormError(error.response.data);
          setLoading(false);
        });
      
      // if (response.ok) {
      //   alert('Cost Entry Saved Successfully!');
      //   volumeClose(); // Close the modal
      // } else {
      //   alert('Error: ' + result.message);
      //   setLoading(false);
      // }
      } catch (error) {
        console.error('Error submitting cost entry:', error);
        alert('Something went wrong!');
        setLoading(false);
      }
  };
  const onCostSubmit = () => {
    // Call login API
    setFormError(null);
    setLoading(true);

    const formData = new FormData();
    const params = {
      job_id: job_id,
      airway_bill_localorder: (airwaybillLocalOrder) ? airwaybillLocalOrder.order_number : '',
      cost_description_id: (costDescription) ? costDescription.id : '',
      cost_type_id: (costType) ? costType.id : '',
      supplier_id: (supplier) ? supplier.id : '',
      cost_unit: costUnit,
      cost_amount: costAmount,
      cost_vat: costVat,
      cost_total: costTotal,
      cost_currency_id: (costCurrency) ? costCurrency.id : '',
      revenue_amount: revenueAmount,
      revenue_vat: revenueVat,
      revenue_total: revenueTotal,
      revenue_currency: (revenueCurrency) ? revenueCurrency.id : '',
      id: costId,
      cost_date: costDate,
      international_vat: internationalVat,
      reference_number: referenceNumber,
    };

    if (costId) {
      axiosCtx.instance.put(`cost/` + costId, params)
        .then((response) => {
          setShowVolume(false);
          setCostFormName('Add');
          getCosts(response.data.data.job_id);

          setCostUnit('');
          setCostAmount('');
          setCostVat('');
          setCostTotal('');
          setCostCurrency(null);
          setAirwaybillLocalOrder(null);
          setCostDescription(null);
          setCostType(null);
          setSupplier(null);

          setRevenueCurrency(null);
          setRevenueAmount('');
          setRevenueVat('');
          setRevenueTotal('');
          setCostDate('');
          setInternationalVat(false);
          setReferenceNumber('');

          setCostId('');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error.response.data);
          // setLoading(false);
        });
    } else {

      axiosCtx.instance.post(`cost`, params)
        .then((response) => {
          setShowVolume(false);
          getCosts(response.data.data.job_id);

          setCostUnit('');
          setCostAmount('');
          setCostVat('');
          setCostTotal('');
          setCostCurrency(null);
          setAirwaybillLocalOrder(null);
          setCostDescription(null);
          setCostType(null);
          setSupplier(null);

          setRevenueCurrency(null);
          setRevenueAmount('');
          setRevenueVat('');
          setRevenueTotal('');
          setCostDate('');
          setInternationalVat(false);
          setReferenceNumber('');
          setLoading(false);
        })
        .catch((error) => {
          setFormError(error.response.data);
          setLoading(false);
        });
    }

  };

//Collection delivery note
const onDeliveryNoteSubmit = () => {
  // Call login API
  setFormError(null);
  setLoading(true);

  const formData = new FormData();
  const params = {
    category: 1,
    job_id: job_id,
    party_id: (party) ? party.id : '',
    job_code: (job) ? job.job_code : '',
    shipper_id: (collectionShipper) ? collectionShipper.id : '',
    port_id: (port) ? port.id : '',
    shipper_ref: (shipperRef) ? shipperRef : '',
    shipper_address: (shipperAddress) ? shipperRef : '',
    consignee_id: (collectionConsignee) ? collectionConsignee.id : '',
    date_of_issue: dateOfIssue,
    collection_date: collectionDate,
   vsl_flt: (podVslFlt) ? podVslFlt.id : '',
   prefix_id:(prefix) ? prefix.id : '',
   hawbhcvbNo : (hawbhcvbNo) ? hawbhcvbNo: '',
   mawbmcvbNo: (mawbmcvbNo) ? mawbmcvbNo : '',
   job_ref: (jobRef) ? jobRef : '',
   weight:(weight) ? weight : '',
   description_of_goods: descriptionOfGoods
  };
 
    axiosCtx.instance.post(`collectionDeliveryNote`, params)
      .then((response) => {
        setShowDeliveryNote(false);
        setShipperRef('');
        setshipperAddress('');
        getCollectionDeliveryNote(job_id);
        setLoading(false);
      })
      .catch((error) => {
        setFormError(error);
        setLoading(false);
      });
  
};

const onBookingConfirmationSubmit = () => {
  // Call login API
  setFormError(null);
  setLoading(true);

  const formData = new FormData();
  const params = {
    category: 2,
    job_id: job_id,
    party_id: (party) ? party.id : '',
    job_code: (job) ? job.job_code : '',
    shipper_id: (collectionShipper) ? collectionShipper.id : '',
    port_id: (port) ? port.id : '',
    shipper_ref: (shipperRef) ? shipperRef : '',
    shipper_address: (shipperAddress) ? shipperRef : '',
    consignee_id: (collectionConsignee) ? collectionConsignee.id : '',
    date_of_issue: dateOfIssue,
    collection_date: collectionDate,
   vsl_flt: (podVslFlt) ? podVslFlt.id : '',
   prefix_id:(prefix) ? prefix.id : '',
   hawbhcvbNo : hawbhcvbNo,
   mawbmcvbNo: mawbmcvbNo,
   job_ref: (jobRef) ? jobRef : '',
   weight: weight,
   description_of_goods: descriptionOfGoods
  };
 
    axiosCtx.instance.post(`collectionDeliveryNote`, params)
      .then((response) => {
     
        setShowBookingConfirmation(false);
    
        setShipperRef('');
        setshipperAddress('');
        getCollectionDeliveryNote(job_id);
        setLoading(false);
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  
};

const onPreAlertSubmit = () => {
  // Call login API
  setFormError(null);
  setLoading(true);

  const formData = new FormData();
  const params = {
    category: 3,
    job_id: job_id,
    party_id: (party) ? party.id : '',
    job_code: (job) ? job.job_code : '',
    shipper_id: (collectionShipper) ? collectionShipper.id : '',
    port_id: (port) ? port.id : '',
    shipper_ref: (shipperRef) ? shipperRef : '',
    shipper_address: (shipperAddress) ? shipperRef : '',
    consignee_id: (collectionConsignee) ? collectionConsignee.id : '',
    date_of_issue: dateOfIssue,
    collection_date: collectionDate,
   vsl_flt: (podVslFlt) ? podVslFlt.id : '',
   prefix_id:(prefix) ? prefix.id : '',
   hawbhcvbNo : hawbhcvbNo,
   mawbmcvbNo: mawbmcvbNo,
   job_ref: (jobRef) ? jobRef : '',
   weight: weight,
   description_of_goods: descriptionOfGoods
  };
 
    axiosCtx.instance.post(`collectionDeliveryNote`, params)
      .then((response) => {
        setShowPreAlert(false);
        setShipperRef('');
        setshipperAddress('');
        getCollectionDeliveryNote(job_id);
        setLoading(false);
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  
};
  //Pod
  const [podFormValues, setPodFormValues] = useState([{ job_number: "", description: "", quantity: 0,piece: 0, remark: "" }])
  const [podEditFormValues, setPodEditFormValues] = useState([{ job_number: "", description: "", quantity: 0,piece: 0, remark: "" }])

  const addPodField = () => {
    setPodFormValues([...podFormValues, { job_number: "", description: "", quantity: 0,piece: 0, remark: "" }])
  };

  const onBlurQuantity = () => {
    const totalQty = podFormValues.reduce((total, item) => total + (parseInt(item.quantity) || 0), 0);
    setPodTotalPackage(totalQty);
  };
  const onBlurPiece = () => {
    const totalPiece = podFormValues.reduce((total, item) => total + (parseInt(item.piece) || 0), 0);
    setPodTotalPiece(totalPiece);
  };

  let removePodField = (index) => {
    let newFormValues = [...podFormValues];
    let qty = newFormValues[index]['quantity'];
    let piece = newFormValues[index]['piece'];
    newFormValues.splice(index, 1);
    setPodFormValues(newFormValues)
    if (qty && !isNaN(qty)) {
      var tot = parseInt(podTotalPackage) - parseInt(qty);
      setPodTotalPackage(tot);
    }
    if (piece && !isNaN(piece)) {
      var tot = parseInt(podTotalPiece) - parseInt(piece);
      setPodTotalPiece(tot);
    }
  }

  const handlePodChange = (i, e) => {
    let formValues = [...podFormValues];
    formValues[i][e.target.name] = e.target.value;
    setPodFormValues(formValues);
  }

  const onPodPdfGenerate = (item) => {

    history.push({
      pathname: 'podpdf',
      state: {
        data: {
          id: podId,
          job_id: job_id,
        },
      }
    });

  };

  const [podConsignee, setPodConsignee] = useState(null);
  const [podOrigin, setPodOrigin] = useState(null);
  const [podblAwb, setPodBlAwb] = useState(null);
  const [podDate, setPodDate] = useState(defaultDate);
  const [podVslFlt, setPodVslFlt] = useState([]);
  const [podTransporter, setPodTransporter] = useState('');

  const [podSupervisor, setPodSupervisor] = useState(authCtx.name);
  const [podDeliveryClerk, setPodDeliveryClerk] = useState('');
  const [podSignName, setPodSignName] = useState('');
  const [podSignature, setPodSignature] = useState('');
  const [podSignDate, setPodSignDate] = useState('');
  const [podSignTime, setPodSignTime] = useState('');
  const [podTotalPackage, setPodTotalPackage] = useState(0);
  const [podTotalPiece, setPodTotalPiece] = useState(0);
  const [podId, setPodId] = useState(0);

  const [isPodLoad, setIsPodLoad] = useState(false);

  const submitPodForm = () => {
    setIsPodLoad(true);
    setFormError(null);
    setLoading(true);
    // Call login API
    const formData = new FormData();
    formData.append("pod_id", podId);
    formData.append("job_id", job_id);
    formData.append("consignee", (podConsignee) ? podConsignee.id : '');
    formData.append("origin", (podOrigin) ? podOrigin.id : '');
    formData.append("b_l_awb", (podblAwb) ? podblAwb.id : '');
    formData.append("date", podDate);
    formData.append("vsl_flt", (podVslFlt) ? podVslFlt.id : '');
    formData.append("transporter", podTransporter);
    formData.append("supervisor", podSupervisor);
    formData.append("delivery_clerk", podDeliveryClerk ?? '');
    formData.append("sign_name", podSignName);

    formData.append("dynamic_fields", JSON.stringify(podFormValues));
    formData.append("total_package", (podTotalPackage) ? podTotalPackage : '');
    formData.append("total_piece", (podTotalPiece) ? podTotalPiece : '');

    axiosCtx.instance.post(`pod`, formData)
      .then((response) => {
        setIsPodLoad(false);
        getPod(job_id);
        console.log(response.data.data);
        setLoading(false);

      })
      .catch((error) => {
        setFormError(error.response.data);
        setIsPodLoad(false);
        setLoading(false);
      });
  }

  const getPod = (job_id) => {
    // Call login API
    axiosCtx.instance.get(`pod/job/` + job_id)
      .then((response) => {
        if (response.data.data) {
          console.log('pod',response.data.data)
          setPodConsignee(response.data.data.consignee_data || null);
          setPodOrigin(response.data.data.origin_data || null);
          setPodBlAwb(response.data.data.blawb || null);
          setPodDate(response.data.data.date);
          setPodVslFlt(response.data.data.vslflt || null);
          setPodTransporter(response.data.data.transporter);
          setPodSupervisor(response.data.data.supervisor);
          setPodDeliveryClerk(response.data.data.delivery_clerk);
          setPodId(response.data.data.id);
          setPodTotalPackage(response.data.data.total_package);
          setPodTotalPiece(response.data.data.total_piece);
          setPodFormValues(JSON.parse(response.data.data.dynamic_fields));
        }
        else {
          setPodConsignee(null);
          setPodOrigin(null);
          setPodDate(defaultDate);
          setPodTransporter('');
          setPodDeliveryClerk('');
          setPodId('');
        }

      })
      .catch((error) => {
      });
  }

  const [podAirwaybill, setPodAirwaybill] = useState([]);

  const getAirwaybillById = (id, from) => {
    axiosCtx.instance.get(`airway-bill/` + id)
      .then((response) => {
        if (from == 'pod') {
          const newData = response.data.data;
  
          // Check if the airway bill already exists in podFormValues
          let formValues = [...podFormValues];
          let existingIndex = formValues.findIndex(item => item.job_number === newData.prefix.prefix + newData.mawb_mcbv_no);
  
          if (existingIndex !== -1) {
            // Update existing record
            formValues[existingIndex] = {
              job_number: newData.prefix.prefix + newData.mawb_mcbv_no,
              description: newData.nature_of_goods,
              quantity: newData.weight,
              piece: newData.piece,
              remark: formValues[existingIndex].remark || ""
            };
          } else {
            // Add a new entry
            formValues.push({
              job_number: newData.prefix.prefix + newData.mawb_mcbv_no,
              description: newData.nature_of_goods,
              quantity: newData.weight,
              piece: newData.piece,
              remark: ""
            });
          }
  
          // Update state
          setPodFormValues(formValues);
  
          // Calculate total quantity and package count
          const totalQty = formValues.reduce((total, item) => total + (parseInt(item.quantity) || 0), 0);
          setPodTotalPackage(totalQty);
          const totalPiece = formValues.reduce((total, item) => total + (parseInt(item.piece) || 0), 0);
          setPodTotalPiece(totalPiece);
        } else {
          setEcForms(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching airway bill", error);
      });
  };
  
  const podConsigneeHandleChange = (event) => {
    setPodConsignee(event.target.value);
  };
  const podOriginHandleChange = (event) => {
    setPodOrigin(event.target.value);
  };
  const podblAwbHandleChange = (event) => {
    setPodBlAwb(event.target.value);
    getAirwaybillById(event.target.value, 'pod');
  };
  const podDateHandleChange = (event) => {
    setPodDate(event.target.value);
  };
  const podVslFltHandleChange = (event) => {
    setPodVslFlt(event.target.value);
  };
  const podTransporterHandleChange = (event) => {
    setPodTransporter(event.target.value);
  };
  const podSupervisorHandleChange = (event) => {
    setPodSupervisor(event.target.value);
  };
  const podDeliveryClerkHandleChange = (event) => {
    setPodDeliveryClerk(event.target.value);
  };

  //Exit Cerftificate
  const [exitFormValues, setExitFormValues] = useState([{ quantity: 0, description: "", value: "" }])

  const [exitTotalQuantity, setExitTotalQuantity] = useState(0);
  const [exitWeight, setExitWeight] = useState(0);
  const [exitDestination, setExitDestination] = useState('');
  const [exitId, setExitId] = useState(0);

  const [exitAgent, setExitAgent] = useState(null);
  const [exitTransitBillNo, setExitTransitBillNo] = useState('');
  const [exitTruckDetails, setExitTruckDetails] = useState('');
  const [exitOrigin, setExitOrigin] = useState('');
  const [exitDate, setExitDate] = useState(defaultDate);
  const [exitAirwayBillNo, setExitAirwayBillNo] = useState(null);
  const [exitPoe, setExitPoe] = useState(null);
  const [exitForms, setExitForms] = useState([]);

  const addExitForm = () => {
    setShowAddEc(true);
  }

  const setEcForms = (data) => {
    let newFormValues = [...exitFormValues];
    newFormValues[0]['quantity'] = data.piece;
    newFormValues[0]['description'] = data.nature_of_goods;
    setExitWeight(data.weight);
    setExitTotalQuantity(data.piece);
  };

  const onExitCertDeleteHandler = (item) => {
    handleShowDeleteExitCert();
    setExitId(item);
  };

  const onEcPrintPress = (item) => {
    history.push({
      pathname: `exitcertificate-pdf/${item.id}`,
      state: {
        data: {
          id: exitId,
          job_id: job_id,
        },
      }
    });

  };
  const onCollectionPrintPress = (item) => {
    history.push({
      pathname: `collection-delivery-note-pdf/${item.id}`,
      state: {
        data: {
          id: item.id,
          job_id: job_id,
        },
      }
    });

  };

const getAirwayBillByJobId = (job_id) => {
  setLoading(true);
  const airwaybillRequest = axiosCtx.instance.get(`airway-bill/job/show/${job_id}`);

  Promise.all([airwaybillRequest]).then(([airwaybillResponse]) => {

      if (airwaybillResponse.status === 200) {
          let response = airwaybillResponse.data.data;
          console.log('response',response);
          // setJobId(response.job_id ? response.job_id : '');
          // setWarehouse(response.warehouse ? response.warehouse : '');
          setShipperRef(response.shipper_ref ? response.shipper_ref : '');
          setCollectionShipper(response.shipper ? response.shipper : '');
        
          setWeight(response.weight ? response.weight : '');
         
          // setNtrOfGoods(response.ntr_of_goods ? response.ntr_of_goods : '');
        
          setHawbhcvbNo(response.hawb_hcbv_no ? response.hawb_hcbv_no : '');
          setPrefix(response.prefix);
          setMawbmcvbNo(response.mawb_mcbv_no);
          setCollectionConsignee(response.consignee ? response.consignee : '');

          setLoading(false);

      }

  }).catch((error) => {
      // TODO: Handle Error
      console.log(error)
      // alert('Sorry, something went wrong!');
      setLoading(false);
  });
};

const onEcEditPress = (item) => {
    setExitAgent(item.ships_agent);
    setExitAirwayBillNo(item.airway_bill_no);
    setExitDate(item.date);
    setExitDestination(item.destination.id);
    setExitFormValues(JSON.parse(item.dynamic_fields))
    setExitId(item.id);
    setExitOrigin(item.origin);
    setExitPoe(item.point_of_exit);
    setExitTotalQuantity(item.total_quantity);
    setExitTransitBillNo(item.transit_bill_no);
    setExitTruckDetails(item.truck_details);
    setExitWeight(item.weight);
    addExitForm();
  }

  const ExitCertDeleteSubmitHandler = () => {
    setLoading(true);
    if (exitId) {
      axiosCtx.instance.delete(`exit-certificate/` + exitId,)
        .then((response) => {
          setLoading(false);
          getExitCertificates(job_id);
          setDeleteMessageExitCert('');
          setShowDeleteExitCert(false);
        })
        .catch((error) => {
          setDeleteMessageExitCert(error.response.data.errors);
          setLoading(false);
        });
    }
  };

  const [showAddEc, setShowAddEc] = useState(false);

  const handleCloseAddEc = () => {
    setShowAddEc(false);
    setExitAgent(null);
    setExitAirwayBillNo();
    setExitDate('');
    setExitDestination('');
    setExitFormValues([...exitFormValues, { quantity: 0, description: "", value: "" }])
    setExitId('');
    setExitOrigin('');
    setExitPoe(null);
    setExitTotalQuantity('');
    setExitTransitBillNo('');
    setExitTruckDetails('');
    setExitWeight('');
  }

  const [showDeleteExitCert, setShowDeleteExitCert] = useState(false);
  const [deleteMessageExitCert, setDeleteMessageExitCert] = useState('');

  const EcSubmitHandler = () => {

  }

  const handleCloseDeleteExitCert = () => {
    setShowDeleteExitCert(false);
    setDeleteMessageExitCert('');
  }

  const handleShowDeleteExitCert = () => setShowDeleteExitCert(true);

  const exitAgentHandleChange = (event) => {
    setExitAgent(event.target.value);
  };

  const exitTransitBillNoHandleChange = (event) => {
    setExitTransitBillNo(event.target.value);
  };

  const exitTruckDetailsHandleChange = (event) => {
    setExitTruckDetails(event.target.value);
  };

  const exitOriginHandleChange = (event) => {
    setExitOrigin(event.target.value);
  };

  const exitDateHandleChange = (event) => {
    setExitDate(event.target.value);
  };

  const exitAirwayBillNoHandleChange = (event) => {
    setExitAirwayBillNo(event.target.value);
    getAirwaybillById(event.target.value, 'airwayill');
  };

  const exitPoeHandleChange = (event) => {
    setExitPoe(event.target.value);
  };

  const addExitField = () => {
    setExitFormValues([...exitFormValues, { quantity: 0, description: "", value: "" }])
  };

  const onBlurExitQuantity = (event) => {
    const exitQty = exitFormValues.reduce((total, currentValue) => (!isNaN(parseInt(currentValue.quantity) + parseInt(total))) ? total = parseInt(total) + parseInt(currentValue.quantity) : total = total, 0);
    setExitTotalQuantity(exitQty);
  }

  let removeExitField = (index) => {
    let newFormValues = [...exitFormValues];
    let qty = newFormValues[index]['quantity'];
    newFormValues.splice(index, 1);
    setExitFormValues(newFormValues)
    if (qty && !isNaN(qty)) {
      var tot = parseInt(exitTotalQuantity) - parseInt(qty);
      setExitTotalQuantity(tot);
    }
  }

  const handleExitFieldChange = (i, e) => {
    let formValues = [...exitFormValues];
    formValues[i][e.target.name] = e.target.value;
    setExitFormValues(formValues);
  }

  const exitWeightHandleChange = (event) => {
    setExitWeight(event.target.value);
  }

  const exitDestinationHandleChange = (event) => {
    setExitDestination(event.target.value);
  }
  const [isExitLoad, setIsExitLoad] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState('job');

  const submitExitForm = () => {
    setIsExitLoad(true);
    setFormError(null);
    setLoading(true);
    // Call login API
    const params = {
      ships_agent: (exitAgent) ? exitAgent.id : '',
      job_id: job_id,
      exit_id: (exitId) ? exitId : '',
      origin: (exitOrigin) ? exitOrigin : '',
      transit_bill_no: (exitTransitBillNo) ? exitTransitBillNo : '',
      truck_details: (exitTruckDetails) ? exitTruckDetails : '',
      date: (exitDate) ? exitDate : '',
      airway_bill_no: (exitAirwayBillNo) ? exitAirwayBillNo.id : '',
      point_of_exit: (exitPoe) ? exitPoe.id : '',
      total_quantity: (exitTotalQuantity) ? exitTotalQuantity : '',
      weight: (exitWeight) ? exitWeight : '',
      destination: (exitDestination) ? exitDestination : '',
      dynamic_fields: JSON.stringify(exitFormValues),
    };

    if (exitId) {
      axiosCtx.instance.put(`exit-certificate/` + exitId, params)
        .then((response) => {
          getExitCertificates(job_id);
          setIsExitLoad(false);
          handleCloseAddEc();
          setLoading(false);
        })
        .catch((error) => {
          setFormError(error.response.data);
          setIsExitLoad(false);
          setLoading(false);
        });

    } else {
      axiosCtx.instance.post(`exit-certificate`, params)
        .then((response) => {
          getExitCertificates(job_id);
          setIsExitLoad(false);
          handleCloseAddEc();
          setLoading(false);
        })
        .catch((error) => {
          setFormError(error.response.data);
          setIsExitLoad(false);
          setLoading(false);
        });
    }
  }

  const getExitCertificates = (job_id) => {
    // Call login API
    axiosCtx.instance.get(`exit-certificate/job/` + job_id)
      .then((response) => {
        setExitForms(response.data.data);
      })
      .catch((error) => {
      });
  }

  const getExitCertificate = (job_id) => {
    // Call login API
    axiosCtx.instance.get(`exit-certificate/job/` + job_id)
      .then((response) => {
        if (response.data.data) {
          setExitAgent(response.data.data.ships_agent);
          setExitTransitBillNo(response.data.data.transit_bill_no);
          setExitTruckDetails(response.data.data.truck_details);
          setExitOrigin(response.data.data.origin);
          setExitDate(response.data.data.date);
          setExitAirwayBillNo(response.data.data.airway_bill_no);
          setExitPoe(response.data.data.point_of_exit);
          setExitTotalQuantity(response.data.data.total_quantity);
          setExitWeight(response.data.data.weight);
          setExitId(response.data.data.id);
          setExitDestination(response.data.data.destination);
          setExitFormValues(JSON.parse(response.data.data.dynamic_fields));
        }
        else {
          setExitAgent(null);
          setExitTransitBillNo('');
          setExitTruckDetails('');
          setExitOrigin('');
          setExitDate(defaultDate);
          setExitAirwayBillNo(null);
          setExitPoe(null);
          setExitTotalQuantity(0);
          setExitWeight(0);
          setExitId('');
          setExitDestination('');
          setExitFormValues([{ quantity: 0, description: "", value: "" }]);
        }

      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }

    getCurrencies();
    getStatues();
    getSubtypes();
    getPorts();
    getJobs();
    getJobtypes();
    getParties();
    getProjects();
    getCostDescriptions();
    getCostTypes();
    getLocations();
    getRacks();
    getAirlinePrefixes();
   
    if (detailpage == 'index') {
      getAirwayBillByJobId(detail.id);
      setJob(detail);
      setJobid(detail.id);
      setJobCode(detail.job_code);
      setJobRef(detail.job_id);
      setStatus(detail.status);
      setJobtype(detail.job_type.id);
      setProject(detail.project.id);
      setProjectLocal(detail.project);
      setPodVslFlt(detail.project);
      setParty(detail.party);
      setPort(detail.port);
      setDateOfExecution(detail.date_of_execution);
      setComments(detail.comments);
      getLocalOrderByJob(detail.id);
      getAirwayBillsLocalOrder(detail.id);
      getCollectionDeliveryNote(detail.id);
      getAirwayBills(detail.id);
      getCosts(detail.id);
      getPod(detail.id);
      getExitCertificates(detail.id);
      (detailTab && detailTab == 'air') ? setDefaultActiveKey('air') : setDefaultActiveKey('job');

    }
    else {
      getAirwayBillByJobId(detail.id);
      setJob(detail);
      setJobid(detail.id);
      setStatus(detail.status);
      setJobtype(detail.job_type_id);
      setProject(detail.project_id);
      setProjectLocal(detail.project);
      setPodVslFlt(detail.project);
      setParty(detail.party);
      setPort(detail.port);
      setDateOfExecution(detail.date_of_execution);
      setComments(detail.comments);
      getLocalOrderByJob(detail.id);
      getAirwayBillsLocalOrder(detail.id);
      getCollectionDeliveryNote(detail.id);
      getAirwayBills(detail.id);
      getCosts(detail.id);
      getPod(detail.id);
      getExitCertificates(detail.id);
      (detailTab && detailTab == 'air') ? setDefaultActiveKey('air') : setDefaultActiveKey('job');
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = localOrders.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectCollectionAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = collectionDeliveryNotes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
    setStatus(null);
    setJobtype(null);
    setProject(null);
    setParty(null);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - localOrders.length) : 0;

  let con_cost_total = 0;
  let con_revenue_total = 0;

  let [loading, setLoading] = useState(false);
  let [costLoading, setCostLoading] = useState(false);
  let [airBillLoading, setAirBillLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");
  const [costFields, setCostFields] = useState([
    {
      id: Date.now(),
      costDescription: "",
      costType: "",
      supplier: "",
      costUnit: "",
      costCurrency: "",
      costAmount: "",
      costVat: "",
      costTotal: "",
      revenueCurrency: "",
      revenueAmount: "",
      revenueVat: "",
      revenueTotal: "",
      costDate: "",
      referenceNumber: "",
      internationalVat: false,
    },
  ]);

  const handleCostFieldChange = (index, field, value) => {
    setCostFields(prevFields => {
      const updatedFields = [...prevFields];
      updatedFields[index] = { ...updatedFields[index], [field]: value };
  
      // Convert to number (default to 0 if empty)
      const costAmount = parseFloat(updatedFields[index].costAmount) || 0;
      const costVat = parseFloat(updatedFields[index].costVat) || 0;
      const revenueAmount = parseFloat(updatedFields[index].revenueAmount) || 0;
      const revenueVat = parseFloat(updatedFields[index].revenueVat) || 0;
  
      // Calculate totals
      updatedFields[index].costTotal = costAmount + (costAmount * (costVat / 100));
      updatedFields[index].revenueTotal = revenueAmount + (revenueAmount * (revenueVat / 100));
  
      return updatedFields;
    });
  };
  
  const addCostField = () => {
    setCostFields([
      ...costFields,
      {
        id: Date.now(),
        costDescription: "",
        costType: "",
        supplier: "",
        costUnit: "",
        costCurrency: "",
        costAmount: "",
        costVat: "",
        costTotal: "",
        revenueCurrency: "",
        revenueAmount: "",
        revenueVat: "",
        revenueTotal: "",
        costDate: "",
        referenceNumber: "",
        internationalVat: false,
      },
    ]);
  };

  const removeCostField = (id) => {
    setCostFields(costFields.filter((field) => field.id !== id));
  };
  return (
    <>
      <div className="content">
        {loading && loading == true ?
          <div className="custom-loader">
            <FadeLoader color={color} loading={loading} css={override} size={150} />
          </div>
          : ''
        }
        <Card className="p-4">
          <Tabs activeKey={(defaultActiveKey) ? defaultActiveKey : 'job'} onSelect={(k) => setDefaultActiveKey(k)} id="uncontrolled-tab-example">
            <Tab eventKey="job" title="Job Details" className="p-3">
              <Row>
                <Col md="6" className="my-4 form-group">
                  <InputField
                    id="job-id"
                    placeholder="Job id"
                    label="Job id"
                    value={job && job.job_id}
                    disabled={true}
                  />
                </Col>
                <Col md="6" className="my-4 form-group">
                  <InputField
                    id="job-code"
                    placeholder="Job code"
                    label="Job code"
                    value={job && job.job_code}
                    disabled={true}
                  />
                </Col>
                <Col md="6" className="my-4">
                  <Dropdown
                    id="statuses"
                    placeholder="Status"
                    label="name"
                    options={statuses}
                    value={status}
                    onChange={(event, newValue) => {
                      setStatus(newValue);
                    }}
                    formError={(formError && formError.errors.status_id) ? formError.errors.status_id : ''}
                  />
                </Col>
                <Col md="6" className="my-4">
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel size="small" id="select1">Job Type</InputLabel>
                    <Select
                      size="small"
                      disabled
                      labelId="select1"
                      id="select1"
                      value={jobtype}
                      onChange={jobtypeHandleChange}
                      label="Job Type"
                    >
                      <MenuItem value=""> <em>None</em> </MenuItem>
                      {jobtypes && jobtypes.map(value => <MenuItem value={value.id}>{value.type}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Col>

                <Col md="6" className="my-4">
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel size="small" id="select1">Project</InputLabel>
                    <Select
                      size="small"
                      disabled
                      labelId="select1"
                      id="select1"
                      value={project}
                      onChange={projectHandleChange}
                      label="Project"
                    >
                      <MenuItem value=""> <em>None</em> </MenuItem>
                      {projects && projects.map(value => <MenuItem value={value.id}>{value.name}</MenuItem>)}
                    </Select>
                  </FormControl>

                </Col>
                <Col md="6" className="my-4">
                  <Dropdown
                    id="parties"
                    placeholder="Party"
                    label="name"
                    options={parties}
                    value={party}
                    onChange={(event, newValue) => {
                      setParty(newValue);
                    }}
                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                  />
                </Col>

                <Col md="6" className="my-4">
                  <Dropdown
                    id="ports"
                    placeholder="POD(Point of Delivery Port) "
                    label="code"
                    label1="name"
                    options={ports}
                    value={port}
                    onChange={(event, newValue) => {
                      setPort(newValue);
                    }}
                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                  />
                </Col>
                <Col md="6" className="my-4 form-group">
                  <InputDate
                    id="date-of-execution"
                    placeholder="Date of Execution"
                    label="Date of Execution"
                    value={dateOfExecution}
                    onChange={(event) => {
                      setDateOfExecution(event.target.value);
                    }}
                  />
                </Col>
                <Col md="6" className="my-4 form-group">
                  <InputField
                    id="comments"
                    placeholder="comments"
                    label="comments"
                    value={comments}
                    onChange={(event) => {
                      setComments(event.target.value);
                    }}
                    multiline={true}
                    rows={4}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end border-top pt-4 mt-4">
              <Button onClick={handleBackClick}>Back</Button>
                <Button onClick={jobUpdateHandler}>Save</Button>
              </div>

            </Tab>
            <Tab eventKey="air" title="Air Waybills" className="p-3">

              <div className="d-flex  my-2">
                <button onClick={() => onAirwayBillAdd(job_id)} className="btn btn-simple">Add Air waybill</button>
              </div>
              <Row>
                <Col md="8"    >
                  {airBillLoading && airBillLoading == true ?
                    <div className="">
                      <FadeLoader color={color} loading={airBillLoading} css={override} size={150} />
                    </div>
                    :
                    <table className="table border">

                      {airwaybills.map((element, index) => (
                        <tr>
                          <td  >  <a href="">{element.prefix.prefix}{element.mawb_mcbv_no}</a> </td>
                          <td className="text-right">
                            <button onClick={() => onAirwayBillPrintHandler(element)} className="btn btn-simple btn-sm mr-2">View</button>

                            <button onClick={() => onAirwayBillEditHandler(element)} className="btn btn-simple btn-primary btn-sm mr-2">Edit</button>
                            <button onClick={() => onAirwayBillDeleteHandler(element.id)} className="btn btn-simple btn-danger btn-sm ">Delete</button>
                          </td>
                        </tr>
                      ))}

                    </table>
                  }
                </Col>
              </Row>

            </Tab>
            <Tab eventKey="local" title="Local Orders" className="p-3">
              <div className="d-flex  my-2">
                <button onClick={onCreateLocalOrder} className="btn btn-simple">Add Local Order</button>
              </div>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={localOrders.length}
                      />
                      <TableBody>
                        {stableSort(localOrders, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox" className="selectCheckbox">
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none" >{row.shipper_id.name}</TableCell>
                                <TableCell>{row.party_id.name}</TableCell>
                                <TableCell>{row.consignee_id.name}</TableCell>
                                <TableCell>{row.order_number}</TableCell>
                                <TableCell>{row.delivery_date}</TableCell>
                                <TableCell>{row.collection_date}</TableCell>
                                <TableCell>
                                  <button onClick={() => onLocalEditPress(row)} className="btn btn-primary btn-sm font-weight-bold mr-3">Edit</button>
                                  <button onClick={() => onLocalOrderDeleteHandler(row.id)} className="btn btn-danger btn-sm font-weight-bold mr-2 ">Delete</button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={localOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>

              </Box>
            </Tab>
            <Tab eventKey="costs" title="Costs" className="p-3">

              <div className="text-right mb-4">
                <button className="btn btn-primary m-0" onClick={volumeAddcostShow}>Add Cost</button>
              </div>
              <Modal show={ShowAddCostVolume} onHide={volumeAddCostClose} size="xl" centered>
  <Modal.Header className="p-4">
    <Modal.Title className="m-0">Cost Entry</Modal.Title>
  </Modal.Header>
  <Modal.Body className="p-4">
    <div className="form-row align-items-center mb-4">
      <div className="col-3">
      <Dropdown
                        id="airwaybillsLocalOrders"
                        placeholder="AWB/Local order no"
                        label="order_number"
                        options={airwaybillsLocalOrders}
                        value={airwaybillLocalOrder}
                        onChange={(event, newValue) => {
                          setAirwaybillLocalOrder(newValue);
                        }}
                        formError={(formError && formError.errors.airway_bill_localorder) ? formError.errors.airway_bill_localorder : ''}
                      />
      </div>
    </div>

    {costFields.map((field, index) => (
      <div key={field.id} className="border p-3 mb-3">
        <div className="form-row align-items-center">
          <div className="col-3">
            <Dropdown id={`costDescriptions-${index}`} placeholder="Cost Description" label="name" options={costDescriptions} value={field.costDescription} onChange={(e, newValue) => handleCostFieldChange(index, "costDescription", newValue)} />
          </div>
          <div className="col-2">
            <Dropdown id={`costTypes-${index}`} placeholder="Cost Type" label="name" options={costTypes} value={field.costType} onChange={(e, newValue) => handleCostFieldChange(index, "costType", newValue)} />
          </div>
          <div className="col-2">
            <Dropdown id={`parties-${index}`} placeholder="Supplier" label="name" options={parties} value={field.supplier} onChange={(e, newValue) => handleCostFieldChange(index, "supplier", newValue)} />
          </div>
          <div className="col-2">
            <InputField id={`costUnit-${index}`} type="number" placeholder="Unit" label="Unit" value={field.costUnit} onChange={(e) => handleCostFieldChange(index, "costUnit", e.target.value)} />
          </div>
          <div className="col-2 text-right">
            <Button variant="danger" onClick={() => removeCostField(field.id)}>Remove</Button>
          </div>
        </div>

        <div className="form-row py-3 align-items-center font-weight-bold mb-4 mt-2 bg-lite-blue">
          <div className="col-6"><span className="ml-3">COST</span></div>
          <div className="col-6"><span className="ml-4">REVENUE</span></div>
        </div>

        <div className="form-row align-items-center mt-2">
          <div className="col-6">
            <div className="form-row align-items-center mr-3">
              <div className="col-7 d-flex form-group">
                <Dropdown id={`currencies-cost-${index}`} placeholder="Currency" label="currency_label" options={currencies} value={field.costCurrency} onChange={(event, newValue) => handleCostFieldChange(index, "costCurrency", newValue)} />
                <InputField id={`cost-amount-${index}`} type="number" placeholder="Amount" label="Amount" value={field.costAmount} onChange={(e) => handleCostFieldChange(index, "costAmount", e.target.value)} />
              </div>
              <div className="col-2 form-group">
                <InputField id={`cost-vat-${index}`} type="number" placeholder="VAT%" label="VAT%" value={field.costVat} onChange={(e) => handleCostFieldChange(index, "costVat", e.target.value)} />
              </div>
              <div className="col-3 form-group">
                <InputField id={`cost-total-${index}`} type="number" placeholder="Total" label="Total" value={field.costTotal} />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-row align-items-center ml-3">
              <div className="col-7 d-flex form-group">
                <Dropdown id={`currencies-revenue-${index}`} placeholder="Currency" label="currency_label" options={currencies} value={field.revenueCurrency} onChange={(event, newValue) => handleCostFieldChange(index, "revenueCurrency", newValue)} />
                <InputField id={`revenue-amount-${index}`} type="number" placeholder="Amount" label="Amount" value={field.revenueAmount} onChange={(e) => handleCostFieldChange(index, "revenueAmount", e.target.value)} />
              </div>
              <div className="col-2 form-group">
                <InputField id={`revenue-vat-${index}`} type="number" placeholder="VAT%" label="VAT%" value={field.revenueVat} onChange={(e) => handleCostFieldChange(index, "revenueVat", e.target.value)} />
              </div>
              <div className="col-3 form-group">
                <InputField id={`revenue-total-${index}`} type="number" placeholder="Total" label="Total" value={field.revenueTotal} />
              </div>
            </div>
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col-2 mb-4">
            <InputDate id={`date-${index}`} placeholder="Date" label="Date" value={field.costDate} onChange={(e) => handleCostFieldChange(index, "costDate", e.target.value)} />
          </div>
          <div className="col-2 mb-4">
            <InputField id={`reference-number-${index}`} placeholder="Reference Number" label="Reference Number" value={field.referenceNumber} onChange={(e) => handleCostFieldChange(index, "referenceNumber", e.target.value)} />
          </div>
          <div className="col-2 mb-4">
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={field.internationalVat} onChange={(e) => handleCostFieldChange(index, "internationalVat", e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />} label="International VAT Excepted" />
            </FormGroup>
          </div>
        </div>
      </div>
    ))}
  {formError &&
                    formError.errors &&
                    formError.errors.cost_currency_id && (
                      <p className="text-danger small pt-2">
                        {formError.errors.costCurrency.join('. ')}
                      </p>
                    )}
                  {formError &&
                    formError.errors &&
                    formError.errors.cost_amount && (
                      <p className="text-danger small pt-2">
                        {formError.errors.costAmount.join('. ')}
                      </p>
                    )}
                  {formError &&
                    formError.errors &&
                    formError.errors.cost_vat && (
                      <p className="text-danger small pt-2">
                        {formError.errors.costVat.join('. ')}
                      </p>
                    )}

                  {formError &&
                    formError.errors &&
                    formError.errors.have_invoice && (
                      <p className="text-danger pt-2">
                        {formError.errors.have_invoice}
                      </p>
                    )}

                  {formError &&
                    formError.errors &&
                    formError.errors.no_airwaybill && (
                      <p className="text-danger pt-2">
                        {formError.errors.no_airwaybill}
                      </p>
                    )}
    <Button variant="success" onClick={addCostField} className="mt-3">+ Add More Cost</Button>
  </Modal.Body>
  <Modal.Footer className="p-4">
    <Button className="mr-3" variant="secondary" onClick={volumeAddCostClose}>Close</Button>
    <Button variant="primary" onClick={submitCostEntry}>Save</Button>
  </Modal.Footer>
</Modal>

              <Modal show={ShowVolume} onHide={volumeClose} size="xl" centered>
                <Modal.Header className="p-4">
                  <Modal.Title className="m-0">{costFormName} Edit Cost  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">

                  <div className="form-row align-items-center mt-4">
                    <div className="col-3 mb-4">
                      <Dropdown
                        id="airwaybillsLocalOrders"
                        placeholder="AWB/Local order no"
                        label="order_number"
                        options={airwaybillsLocalOrders}
                        value={airwaybillLocalOrder}
                        onChange={(event, newValue) => {
                          setAirwaybillLocalOrder(newValue);
                        }}
                        formError={(formError && formError.errors.airway_bill_localorder) ? formError.errors.airway_bill_localorder : ''}
                      />
                    </div>
                    <div className="col-3 mb-4">
                      <Dropdown
                        id="costDescriptions"
                        placeholder="Cost Description"
                        label="name"
                        options={costDescriptions}
                        value={costDescription}
                        onChange={(event, newValue) => {
                          setCostDescription(newValue);
                        }}
                        formError={(formError && formError.errors.cost_description_id) ? formError.errors.cost_description_id : ''}
                      />
                    </div>
                    <div className="col-2 mb-4">
                      <Dropdown
                        id="costTypes"
                        placeholder="Cost Type"
                        label="name"
                        options={costTypes}
                        value={costType}
                        onChange={(event, newValue) => {
                          setCostType(newValue);
                        }}
                        formError={(formError && formError.errors.cost_type_id) ? formError.errors.cost_type_id : ''}
                      />
                    </div>
                    <div className="col-2 mb-4">
                      <Dropdown
                        id="parties"
                        placeholder="Supplier"
                        label="name"
                        options={parties}
                        value={supplier}
                        onChange={(event, newValue) => {
                          setSupplier(newValue);
                        }}
                        formError={(formError && formError.errors.supplier_id) ? formError.errors.supplier_id : ''}
                      />
                    </div>
                    <div className="col-2 mb-4">
                      <InputField
                        id="Unit"
                        type="number"
                        placeholder="Unit"
                        label="Unit"
                        value={costUnit}
                        onChange={costUnitHandleChange}
                        formError={(formError && formError.errors.cost_unit) ? formError.errors.cost_unit : ''}
                      />
                    </div>

                  </div>

                  <div className="form-row py-3 align-items-center font-weight-bold mb-4 mt-2 bg-lite-blue">
                    <div className="col-6"><span className="ml-3">COST </span> </div>
                    <div className="col-6"> <span className="ml-4">REVENUE</span>        </div>
                  </div>

                  <div className="form-row align-items-center mt-2">
                    <div className="col-6">
                      <div className="form-row align-items-center mr-3">
                        <div className="col-7 d-flex form-group">
                          <Dropdown
                            id="currencies"
                            placeholder="Currency"
                            label="currency_label"
                            options={currencies}
                            value={costCurrency}
                            onChange={(event, newValue) => {
                              setCostCurrency(newValue);
                            }}
                          />
                          <InputField
                            id="cost-amount"
                            type="number"
                            placeholder="Amount"
                            label="Amount"
                            value={costAmount}
                            onChange={costAmountHandleChange}
                            onBlur={costVatBlur}
                          />
                        </div>
                        <div className="col-2  form-group">
                          <InputField
                            id="cost-vat"
                            type="number"
                            placeholder="VAT%"
                            label="VAT%"
                            value={costVat}
                            onChange={costVatHandleChange}
                            onBlur={costVatBlur}
                            formError={(formError && formError.errors.cost_vat) ? formError.errors.cost_vat : ''}
                          />
                        </div>
                        <div className="col-3  form-group">
                          <InputField
                            id="cost-total"
                            type="number"
                            placeholder="Total"
                            label="Total"
                            value={costTotal}
                            formError={(formError && formError.errors.cost_total) ? formError.errors.cost_total : ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-row align-items-center ml-3">
                        <div className="col-7 d-flex form-group">
                          <Dropdown
                            id="currencies"
                            placeholder="Currency"
                            label="currency_label"
                            options={currencies}
                            value={revenueCurrency}
                            onChange={(event, newValue) => {
                              setRevenueCurrency(newValue);
                            }}
                          />
                          <InputField
                            id="revenue-amount"
                            type="number"
                            placeholder="Amount"
                            label="Amount"
                            value={revenueAmount}
                            onChange={revenueAmountHandleChange}
                            onBlur={revenueVatBlur}
                          />
                        </div>
                        <div className="col-2  form-group">
                          <InputField
                            id="revenue-vat"
                            type="number"
                            placeholder="VAT%"
                            label="VAT%"
                            value={revenueVat}
                            onChange={revenueVatHandleChange}
                            onBlur={revenueVatBlur}
                          />
                        </div>
                        <div className="col-3  form-group">
                          <InputField
                            id="revenue-total"
                            type="number"
                            placeholder="Total"
                            label="Total"
                            value={revenueTotal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row mt-2">
                    <div className="col-2 mb-4">
                      <InputDate
                        id="Date"
                        placeholder="Date"
                        label="Date"
                        value={costDate}
                        onChange={costDateHandleChange}
                        formError={(formError && formError.errors.cost_date) ? formError.errors.cost_date : ''}
                      />
                    </div>
                    <div className="col-2 mb-4">
                      <InputField
                        id="Reference Number"
                        placeholder="Reference Number"
                        label="Reference Number"
                        value={referenceNumber}
                        onChange={referenceNumberHandleChange}
                        formError={(formError && formError.errors.reference_number) ? formError.errors.reference_number : ''}
                      />
                    </div>
                    <div className="col-2 mb-4">
                      <FormGroup>
                        <FormControlLabel control={<Checkbox
                          checked={internationalVat}
                          onChange={internationalVatHandleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        } label="International VAT Excepted" />
                      </FormGroup>
                    </div>
                  </div>

                  {formError &&
                    formError.errors &&
                    formError.errors.cost_currency_id && (
                      <p className="text-danger small pt-2">
                        {formError.errors.cost_currency_id.join('. ')}
                      </p>
                    )}
                  {formError &&
                    formError.errors &&
                    formError.errors.cost_amount && (
                      <p className="text-danger small pt-2">
                        {formError.errors.cost_amount.join('. ')}
                      </p>
                    )}
                  {formError &&
                    formError.errors &&
                    formError.errors.cost_vat && (
                      <p className="text-danger small pt-2">
                        {formError.errors.cost_vat.join('. ')}
                      </p>
                    )}

                  {formError &&
                    formError.errors &&
                    formError.errors.have_invoice && (
                      <p className="text-danger pt-2">
                        {formError.errors.have_invoice}
                      </p>
                    )}

                  {formError &&
                    formError.errors &&
                    formError.errors.no_airwaybill && (
                      <p className="text-danger pt-2">
                        {formError.errors.no_airwaybill}
                      </p>
                    )}

                </Modal.Body>
                <Modal.Footer className="p-4">
                  <Button className="mr-3"
                    variant="secondary"
                    onClick={volumeClose}>
                    Close
                  </Button>
                  {costId && <Button className="mr-3 btn-danger"
                    variant="secondary"
                    onClick={onDeleteCost}>
                    Delete
                  </Button>
                  }
                  <Button variant="primary" onClick={onCostSubmit}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>

              {costLoading && costLoading == true ?
                <div className="">
                  <FadeLoader color={color} loading={costLoading} css={override} size={150} />
                </div>
                :
                <Table responsive className="custom-table border-bottom">

                  <thead >
                    <tr>
                      <th colSpan={8}> COST  </th>
                      <th colSpan={6}> REVENUE  </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Cost Description </th>
                      <th>Unit </th>
                      <th>Cost type  </th>
                      <th>Supplier  </th>
                      <th> Currency </th>
                      <th> Amount  </th>
                      <th>  VAT% </th>
                      <th> Total  </th>
                      <th> Currency  </th>
                      <th> Amount  </th>
                      <th>  VAT% </th>
                      <th> Total  </th>
                      <th> P/L (AED)  </th>
                      <th>   </th>
                    </tr>
                    {
                      costs && costs.map((element) => {
                        return (
                          <>
                            <tr>
                              <td >
                                <div className="font-weight-bold pt-4">{element.order_no}</div>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td> </td>
                              <td> </td>
                              <td> </td>
                            </tr>

                            {
                              element.costs && element.costs.map((costDetail) => {
                                con_cost_total = costDetail.cost_total * costDetail.cost_to_aed_rate;
                                con_revenue_total = costDetail.revenue_total * costDetail.revenue_to_aed_rate;

                                let total = parseFloat(con_revenue_total) - parseFloat(con_cost_total);
                                return (
                                  <tr>
                                    <td> {costDetail.cost_description.name} </td>
                                    <td> {costDetail.cost_unit} </td>
                                    <td> {costDetail.cost_type.name}  </td>
                                    <td> {costDetail.supplier.name}  </td>
                                    <td> {costDetail.cost_currency_id != null && costDetail.cost_currency_id.currency_label ? costDetail.cost_currency_id.currency_label : ''}  </td>
                                    <td align="right"> {costDetail.cost_amount}  </td>
                                    <td align="right"> {costDetail.cost_vat}  </td>
                                    <td align="right"> {costDetail.cost_total}  </td>
                                    <td> {costDetail.revenue_currency != null && costDetail.revenue_currency.currency_label ? costDetail.revenue_currency.currency_label : ''}  </td>
                                    <td align="right"> {costDetail.revenue_amount}  </td>
                                    <td align="right"> {costDetail.revenue_vat}   </td>
                                    <td align="right"> {costDetail.revenue_total}   </td>
                                    <td align="right"> {total.toFixed(2)}   </td>
                                    <td className="text-right">
                                      <button onClick={() => onCostEditPress(costDetail, element)} className="btn btn-primary btn-sm font-weight-bold mr-3">Edit</button>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </>
                        );
                      })
                    }
                  </tbody>
                </Table>
              }
              <div className="d-flex justify-content-end border-top-0 pt-4 ">
              </div>
            </Tab>
            <Tab eventKey="POD" title="POD" className="p-3">
              <div className="text-right">

                {podId ? <button onClick={onPodPdfGenerate} className="btn btn-simple ml-3 my-0" >Generate PDF  </button> : ''}

              </div>
              <Table className="table-borderless" >
                <tr>
                  <td className="font-weight-bold">Consignee :</td>
                  <td>
                    <Dropdown
                      id="parties"
                      placeholder="Consignee"
                      label="name"
                      options={parties}
                      value={podConsignee}
                      onChange={(event, newValue) => {
                        setPodConsignee(newValue);
                      }}
                      formError={(formError && formError.errors.consignee) ? formError.errors.consignee : ''}
                    />
                  </td>
                  <td className="font-weight-bold">Origin :</td>
                  <td>
                    <Dropdown
                      id="currencies"
                      placeholder="Origin"
                      label="country_name"
                      options={currencies}
                      value={podOrigin}
                      onChange={(event, newValue) => {
                        setPodOrigin(newValue);
                      }}
                      formError={(formError && formError.errors.origin) ? formError.errors.origin : ''}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">B/L/AWB :</td>
                  <td>
  <FormControl variant="outlined" className="w-100">
    <Autocomplete
      value={podblAwb || null}
      size="small"
      onChange={(event, newValue) => {
        setPodBlAwb(newValue);
        if (newValue) {
          getAirwaybillById(newValue.id, 'pod');
        }
      }}
      id="status-box-demo"
      options={airwaybills}
      getOptionLabel={(option) => option.prefix.prefix + option.mawb_mcbv_no}
      renderInput={(params) => <TextField {...params} label="B/L/AWB" />}
    />
    {formError && formError.errors?.b_l_awb && (
      <p className="text-danger small pt-2">
        {formError.errors.b_l_awb.join('. ')}
      </p>
    )}
  </FormControl>
</td>

                  <td className="font-weight-bold">Date :</td>
                  <td>
                    <InputDate
                      id="date"
                      placeholder="Date"
                      label="Date"
                      value={podDate}
                      onChange={podDateHandleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">VSL / FLT :</td>
                  <td>
                    <Dropdown
                      id="projects"
                      placeholder="VSL / FLT"
                      label="name"
                      options={projects}
                      value={podVslFlt}
                      onChange={(event, newValue) => {
                        setPodVslFlt(newValue);
                      }}
                      formError={(formError && formError.errors.vsl_flt) ? formError.errors.vsl_flt : ''}
                    />
                  </td>
                  <td className="font-weight-bold">Transporter :</td>
                  <td> <TextField value={podTransporter} onChange={podTransporterHandleChange} size="small" className="w-100" id="outlined-basic" label="Transporter" variant="outlined" />  </td>
                </tr>
              </Table>

              <Table className="table-bordered mt-4 mb-0" >
                <thead className="font-weight-bold">
                  <tr>
                    <td> Job Number / Marks  </td>
                    <td> Description of Packages </td>
                    <td> Quantity  </td>
                    <td> Piece  </td>
                    <td> Remarks  </td>
                    <td>  </td>
                  </tr>
                </thead>

                {podFormValues && podFormValues.map((element, index) => (

                  <tr>
                    <td> <TextField name="job_number" value={element.job_number ?? ''} onChange={e => handlePodChange(index, e)} size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                    <td> <TextField name="description" value={element.description ?? ''} onChange={e => handlePodChange(index, e)} size="small" className="w-100" id="outlined-basic" variant="outlined" />   </td>
                    <td> <TextField name="quantity" value={element.quantity ?? ''} onBlur={onBlurQuantity} onChange={e => handlePodChange(index, e)} size="small" className="w-100" id="outlined-basic" variant="outlined" /> </td>
                    <td> <TextField name="quantity" value={element.piece ?? ''} onBlur={onBlurPiece} onChange={e => handlePodChange(index, e)} size="small" className="w-100" id="outlined-basic" variant="outlined" /> </td>
                    <td> <TextField name="remark" value={element.remark ?? ''} onChange={e => handlePodChange(index, e)} size="small" className="w-100" id="outlined-basic" variant="outlined" />  </td>
                    <td>
                      <DeleteIcon
                        onClick={() => removePodField(index)}
                        className="text-danger cursor-p">
                      </DeleteIcon>
                    </td>

                  </tr>
                ))}

                <tr className="font-weight-bold">
                  <td colSpan={2} align="right"> Total Weight   </td>
                  <td>  {podTotalPackage} </td>
                 
                </tr>
                <tr className="font-weight-bold">
                  <td colSpan={2} align="right"> Total Packages   </td>
                  <td>  {podTotalPiece} </td>
                 
                </tr>
              </Table>

              <div className="mt-5">
                <Row>
                  <Col md="7">
                    <Row className="mb-4 align-items-center">
                      <Col md="5">
                        <span className="font-weight-bold"> Prepared by </span>
                      </Col>
                      <Col md="7">
                        <InputField
                          id="Supervisor"
                          placeholder="Supervisor"
                          label="Supervisor"
                          value={podSupervisor}
                          onChange={podSupervisorHandleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="  align-items-center">
                      <Col md="5">
                        <span className="font-weight-bold"> Delivery Clerk </span>
                      </Col>
                      <Col md="7">
                        <InputField
                          id="delivery"
                          placeholder="Delivery Clerk"
                          label="Delivery Clerk"
                          value={podDeliveryClerk ?? ''}
                          onChange={podDeliveryClerkHandleChange}
                          multiline={true}
                          rows={4}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md="5">

                    <h6 className="mb-4 ml-2 pl-1">Signature of Consignee</h6>

                    <Table className="table-borderless">
                      <tr>
                        <td className="font-weight-bold">Name :</td>
                        <td> -------------------------------
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Signature :</td>
                        <td> -------------------------------
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Date :</td>
                        <td> -------------------------------
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Time :</td>
                        <td> -------------------------------
                        </td>
                      </tr>
                    </Table>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end border-top-0 pt-4 ">
                  {podId ? <button onClick={() => onPodDeleteHandler()} className="btn btn-danger">
                    Delete
                  </button> : ''}
                  <button onClick={() => submitPodForm()} className="btn btn-primary">
                    {isPodLoad && <i className="fa fa-spinner fa-spin"></i>}
                    Submit
                  </button>
                </div>
                {formError &&
                  formError.errors && (
                    <p className="text-danger small pt-2">
                      {formError.message}
                    </p>
                  )}
              </div>
            </Tab>
            <Tab eventKey="Certificate" title="Exit Certificate" className="p-3">
              <div className="d-flex  my-2">
                <button onClick={addExitForm} className="btn btn-simple">Add Exit Certificate</button>
              </div>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                    >
                      <EnhancedTableHeadEc
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={exitForms.length}
                      />
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(exitForms, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox" className="selectCheckbox">
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none" >{row.ships_agent.name}</TableCell>
                                <TableCell>{row.prefix.prefix}{row.airway_bill_no.mawb_mcbv_no}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.origin}</TableCell>
                                <TableCell>
                                  <button onClick={() => onEcEditPress(row)} className="btn  btn-simple btn-primary btn-sm font-weight-bold mr-2">Edit</button>
                                  <button onClick={() => onEcPrintPress(row)} className="btn  btn-simple btn-dark btn-sm font-weight-bold  mr-2">Print</button>
                                  <button onClick={() => onExitCertDeleteHandler(row.id)} className="btn  btn-simple btn-danger btn-sm font-weight-bold ">Delete</button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={localOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>

              </Box>

            </Tab>
            <Tab eventKey="deliver_booking_alert" title="Delivery/Booking/Pre Alert" className="p-3">

<div className="text-right mb-4">
  <button className="btn btn-primary m-1" onClick={DeliveryNoteShow}>Create New Delivery Note</button>
  <button className="btn btn-primary m-1" onClick={BookingConfirmationShow}>Booking Confirmation</button>
  <button className="btn btn-primary m-1" onClick={PreAlertShow}>Pre Alert</button>
</div>
{/* deliverynote modal */}
<Modal show={ShowDeliveryNote} onHide={DeliveryNoteClose} size="xl" centered>
  <Modal.Header className="p-4">
    <Modal.Title className="m-0">Add Delivery Note </Modal.Title>
  </Modal.Header>
  <Modal.Body className="p-4">

    <div className="form-row align-items-center mt-4">
      <div className="col-3 mb-4">
      <Dropdown
                    id="parties"
                    placeholder="Party"
                    label="name"
                    options={parties}
                    value={party}
                    onChange={(event, newValue) => {
                      setParty(newValue);
                    }}
                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                  />
      </div>
      <div className="col-3 mb-4">
      <InputField
                    id="collection-job-code"
                    placeholder="Job code"
                    label="Job code"
                    value={jobCode}
                    disabled={true}
                  />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_ref"
       placeholder="Shipper Reference"
      label="Shipper Reference"
      value={shipperRef}
      onChange={shipperRefHandleChange}
      formError={(formError && formError.errors.shipper_ref) ? formError.errors.shipper_ref : ''}
        />                              
      </div>
     
      <div className="col-2 mb-4">
      <InputDate
           id="collection_date"
         placeholder="Collection Date"
         label="Collection Date"
          value={collectionDate}
       onChange={collectionDateHandleChange}
       />                                
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_address"
       placeholder="Shipper Address"
      label="Shipper Address"  
       value={shipperAddress}
        onChange={shipperAddressChange}
        formError={(formError && formError.errors.shipper_address) ? formError.errors.shipper_address : ''}
        />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="collection_job_ref"
       placeholder="Job Ref"
      label="Job Ref"  
      value={jobRef}
      disabled={true}
        formError={(formError && formError.errors.job_ref) ? formError.errors.job_ref : ''}
        />
        </div>

        <div className="col-2 mb-4">
        <InputDate
           id="date_of_issue"
         placeholder=" Date"
         label="Date of issue"
          value={dateOfIssue}
       onChange={dateOfIssueChange}
       />     
</div>
<div className="col-2 mb-4">
<Dropdown
                                            id="prefixes"
                                            placeholder="Airline Prefix"
                                            label="prefix"
                                            options={prefixes}
                                            value={prefix}
                                            onChange={(event, newValue) => {
                                                setPrefix(newValue);
                                            }}
                                            formError={(formError && formError.errors.airline_prefix_id) ? formError.errors.airline_prefix_id : ''}
                                        />
                                        </div>
<div className="col-2 mb-4">
<InputField
                                            id="MAWB/MCBV No"
                                            placeholder="MAWB/MCBV No"
                                            label="MAWB/MCBV No"
                                            value={mawbmcvbNo}
                                            onChange={mawbNoHandleChange}
                                            formError={(formError && formError.errors.mawb_mcbv_no) ? formError.errors.mawb_mcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="HAWB/HCBV No"
                                            placeholder="HAWB/HCBV No"
                                            label="HAWB/HCBV No"
                                            value={hawbhcvbNo}
                                            onChange={hawbNoHandleChange}
                                            formError={(formError && formError.errors.hawb_hcbv_no) ? formError.errors.hawb_hcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                      id="projects"
                      placeholder="VSL / FLT"
                      label="name"
                      options={projects}
                      value={podVslFlt}
                      onChange={(event, newValue) => {
                        setPodVslFlt(newValue);
                      }}
                      formError={(formError && formError.errors.vsl_flt) ? formError.errors.vsl_flt : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                    id="ports"
                    placeholder="POD(Point of Delivery Port) "
                    label="code"
                    label1="name"
                    options={ports}
                    value={port}
                    onChange={(event, newValue) => {
                      setPort(newValue);
                    }}
                    formError={(formError && formError.errors.port_id) ? formError.errors.port_id : ''}
                  />
</div>
<div className="col-3 mb-4" >
<Dropdown
        
          id="parties"
          placeholder="Shipper"
          label="name"
          options={parties}
          value={collectionShipper}
          onChange={(event, newValue) => {
              setCollectionShipper(newValue);
          }}
          formError={(formError && formError.errors.shipper_id) ? formError.errors.shipper_id : ''}
      />
</div>
<div className="col-3 mb-4" >
<Dropdown
    id="parties"
    placeholder="Consignee"
    label="name"
    options={parties}
    value={collectionConsignee}
    onChange={(event, newValue) => {
        setCollectionConsignee(newValue);
    }}
    formError={(formError && formError.errors.consignee_id) ? formError.errors.consignee_id : ''}                                        
    />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="description-of-Goods"
                                            placeholder="Description of Goods"
                                            label="Description of Goods"
                                            value={descriptionOfGoods}
                                            onChange={descriptionOfGoodsHandleChange}
                                            formError={(formError && formError.errors.description_of_goods) ? formError.errors.description_of_goods : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="gross_Weight"
                                            placeholder="Gross Weight"
                                            label="Gross Weight"
                                            value={weight}
                                            onChange={weightHandleChange}
                                            formError={(formError && formError.errors.weight) ? formError.errors.weight : ''}
                                        />
                                        {/* <button className="btn btn-simple flex-shrink-0 h-100 my-0" disabled>KG </button> */}
</div>
<div className="col-2 mb-4">
  
</div>
<div className="col-2 mb-4">
  
</div>

    </div>

    {formError &&
      formError.errors &&
      formError.errors.cost_currency_id && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_currency_id.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_amount && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_amount.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_vat && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_vat.join('. ')}
        </p>
      )}

    {formError &&
      formError.errors &&
      formError.errors.have_invoice && (
        <p className="text-danger pt-2">
          {formError.errors.have_invoice}
        </p>
      )}

  </Modal.Body>
  <Modal.Footer className="p-4">
    <Button className="mr-3"
      variant="secondary"
      onClick={DeliveryNoteClose}>
      Close
    </Button>
    
    <Button variant="primary" onClick={onDeliveryNoteSubmit}>
      Save  
    </Button>
  </Modal.Footer>
</Modal>
{/* Booking confirmtion modal */}
<Modal show={ShowBookingConfirmation} onHide={BookingConfirmationClose} size="xl" centered>
  <Modal.Header className="p-4">
    <Modal.Title className="m-0">Add Booking Confirmation </Modal.Title>
  </Modal.Header>
  <Modal.Body className="p-4">

    <div className="form-row align-items-center mt-4">
      <div className="col-3 mb-4">
      <Dropdown
                    id="parties"
                    placeholder="Party"
                    label="name"
                    options={parties}
                    value={party}
                    onChange={(event, newValue) => {
                      setParty(newValue);
                    }}
                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                  />
      </div>
      <div className="col-3 mb-4">
      <InputField
                    id="collection-job-code"
                    placeholder="Job code"
                    label="Job code"
                    value={jobCode}
                    disabled={true}
                  />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_ref"
       placeholder="Shipper Reference"
      label="Shipper Reference"
      value={shipperRef}
      onChange={shipperRefHandleChange}
      formError={(formError && formError.errors.shipper_ref) ? formError.errors.shipper_ref : ''}
        />                              
      </div>
     
      <div className="col-2 mb-4">
      <InputDate
           id="collection_date"
         placeholder="Collection Date"
         label="Collection Date"
          value={collectionDate}
       onChange={collectionDateHandleChange}
       />                                
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_address"
       placeholder="Shipper Address"
      label="Shipper Address"  
       value={shipperAddress}
        onChange={shipperAddressChange}
        formError={(formError && formError.errors.shipper_address) ? formError.errors.shipper_address : ''}
        />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="collection_job_ref"
       placeholder="Job Ref"
      label="Job Ref"  
      value={jobRef}
      disabled={true}
        formError={(formError && formError.errors.job_ref) ? formError.errors.job_ref : ''}
        />
        </div>

        <div className="col-2 mb-4">
        <InputDate
           id="date_of_issue"
         placeholder=" Date"
         label="Date of issue"
          value={dateOfIssue}
       onChange={dateOfIssueChange}
       />     
</div>
<div className="col-2 mb-4">
<Dropdown
                                            id="prefixes"
                                            placeholder="Airline Prefix"
                                            label="prefix"
                                            options={prefixes}
                                            value={prefix}
                                            onChange={(event, newValue) => {
                                                setPrefix(newValue);
                                            }}
                                            formError={(formError && formError.errors.airline_prefix_id) ? formError.errors.airline_prefix_id : ''}
                                        />
                                        </div>
<div className="col-2 mb-4">
<InputField
                                            id="MAWB/MCBV No"
                                            placeholder="MAWB/MCBV No"
                                            label="MAWB/MCBV No"
                                            value={mawbmcvbNo}
                                            onChange={mawbNoHandleChange}
                                            formError={(formError && formError.errors.mawb_mcbv_no) ? formError.errors.mawb_mcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="HAWB/HCBV No"
                                            placeholder="HAWB/HCBV No"
                                            label="HAWB/HCBV No"
                                            value={hawbhcvbNo}
                                            onChange={hawbNoHandleChange}
                                            formError={(formError && formError.errors.hawb_hcbv_no) ? formError.errors.hawb_hcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                      id="projects"
                      placeholder="VSL / FLT"
                      label="name"
                      options={projects}
                      value={podVslFlt}
                      onChange={(event, newValue) => {
                        setPodVslFlt(newValue);
                      }}
                      formError={(formError && formError.errors.vsl_flt) ? formError.errors.vsl_flt : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                    id="ports"
                    placeholder="POD(Point of Delivery Port) "
                    label="code"
                    label1="name"
                    options={ports}
                    value={port}
                    onChange={(event, newValue) => {
                      setPort(newValue);
                    }}
                    formError={(formError && formError.errors.port_id) ? formError.errors.port_id : ''}
                  />
</div>
<div className="col-3 mb-4" hidden>
<Dropdown
        
          id="parties"
          placeholder="Shipper"
          label="name"
          options={parties}
          value={collectionShipper}
          onChange={(event, newValue) => {
              setCollectionShipper(newValue);
          }}
          formError={(formError && formError.errors.shipper_id) ? formError.errors.shipper_id : ''}
      />
</div>
<div className="col-3 mb-4" hidden>
<Dropdown
    id="parties"
    placeholder="Consignee"
    label="name"
    options={parties}
    value={collectionConsignee}
    onChange={(event, newValue) => {
        setCollectionConsignee(newValue);
    }}
    formError={(formError && formError.errors.consignee_id) ? formError.errors.consignee_id : ''}                                        
    />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="description-of-Goods"
                                            placeholder="Description of Goods"
                                            label="Description of Goods"
                                            value={descriptionOfGoods}
                                            onChange={descriptionOfGoodsHandleChange}
                                            formError={(formError && formError.errors.description_of_goods) ? formError.errors.description_of_goods : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="gross_Weight"
                                            placeholder="Gross Weight"
                                            label="Gross Weight"
                                            value={weight}
                                            onChange={weightHandleChange}
                                            formError={(formError && formError.errors.weight) ? formError.errors.weight : ''}
                                        />
                                        {/* <button className="btn btn-simple flex-shrink-0 h-100 my-0" disabled>KG </button> */}
</div>
<div className="col-2 mb-4">
  
</div>
<div className="col-2 mb-4">
  
</div>

    </div>

    {formError &&
      formError.errors &&
      formError.errors.cost_currency_id && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_currency_id.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_amount && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_amount.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_vat && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_vat.join('. ')}
        </p>
      )}

    {formError &&
      formError.errors &&
      formError.errors.have_invoice && (
        <p className="text-danger pt-2">
          {formError.errors.have_invoice}
        </p>
      )}
       
  </Modal.Body>
  <Modal.Footer className="p-4">
    <Button className="mr-3"
      variant="secondary"
      onClick={BookingConfirmationClose}>
      Close
    </Button>
    
    <Button variant="primary" onClick={onBookingConfirmationSubmit}>
      Save  
    </Button>
  </Modal.Footer>
</Modal>

{/* Pre alert modal */}

<Modal show={ShowPreAlert} onHide={PreAlertClose} size="xl" centered>
  <Modal.Header className="p-4">
    <Modal.Title className="m-0">Add Pre Alert </Modal.Title>
  </Modal.Header>
  <Modal.Body className="p-4">

    <div className="form-row align-items-center mt-4">
      <div className="col-3 mb-4">
      <Dropdown
                    id="parties"
                    placeholder="Party"
                    label="name"
                    options={parties}
                    value={party}
                    onChange={(event, newValue) => {
                      setParty(newValue);
                    }}
                    formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                  />
      </div>
      <div className="col-3 mb-4">
      <InputField
                    id="collection-job-code"
                    placeholder="Job code"
                    label="Job code"
                    value={jobCode}
                    disabled={true}
                  />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_ref"
       placeholder="Shipper Reference"
      label="Shipper Reference"
      value={shipperRef}
      onChange={shipperRefHandleChange}
      formError={(formError && formError.errors.shipper_ref) ? formError.errors.shipper_ref : ''}
        />                              
      </div>
     
      <div className="col-2 mb-4">
      <InputDate
           id="collection_date"
         placeholder="Collection Date"
         label="Collection Date"
          value={collectionDate}
       onChange={collectionDateHandleChange}
       />                                
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="shipper_address"
       placeholder="Shipper Address"
      label="Shipper Address"  
       value={shipperAddress}
        onChange={shipperAddressChange}
        formError={(formError && formError.errors.shipper_address) ? formError.errors.shipper_address : ''}
        />
      </div>
      <div className="col-2 mb-4">
      <InputField
        id="collection_job_ref"
       placeholder="Job Ref"
      label="Job Ref"  
      value={jobRef}
      disabled={true}
        formError={(formError && formError.errors.job_ref) ? formError.errors.job_ref : ''}
        />
        </div>

        <div className="col-2 mb-4">
        <InputDate
           id="date_of_issue"
         placeholder=" Date"
         label="Date of issue"
          value={dateOfIssue}
       onChange={dateOfIssueChange}
       />     
</div>
<div className="col-2 mb-4">
<Dropdown
                                            id="prefixes"
                                            placeholder="Airline Prefix"
                                            label="prefix"
                                            options={prefixes}
                                            value={prefix}
                                            onChange={(event, newValue) => {
                                                setPrefix(newValue);
                                            }}
                                            formError={(formError && formError.errors.airline_prefix_id) ? formError.errors.airline_prefix_id : ''}
                                        />
                                        </div>
<div className="col-2 mb-4">
<InputField
                                            id="MAWB/MCBV No"
                                            placeholder="MAWB/MCBV No"
                                            label="MAWB/MCBV No"
                                            value={mawbmcvbNo}
                                            onChange={mawbNoHandleChange}
                                            formError={(formError && formError.errors.mawb_mcbv_no) ? formError.errors.mawb_mcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="HAWB/HCBV No"
                                            placeholder="HAWB/HCBV No"
                                            label="HAWB/HCBV No"
                                            value={hawbhcvbNo}
                                            onChange={hawbNoHandleChange}
                                            formError={(formError && formError.errors.hawb_hcbv_no) ? formError.errors.hawb_hcbv_no : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                      id="projects"
                      placeholder="VSL / FLT"
                      label="name"
                      options={projects}
                      value={podVslFlt}
                      onChange={(event, newValue) => {
                        setPodVslFlt(newValue);
                      }}
                      formError={(formError && formError.errors.vsl_flt) ? formError.errors.vsl_flt : ''}
                                        />
</div>
<div className="col-2 mb-4">
<Dropdown
                    id="ports"
                    placeholder="POD(Point of Delivery Port) "
                    label="code"
                    label1="name"
                    options={ports}
                    value={port}
                    onChange={(event, newValue) => {
                      setPort(newValue);
                    }}
                    formError={(formError && formError.errors.port_id) ? formError.errors.port_id : ''}
                  />
</div>
<div className="col-3 mb-4" hidden>
<Dropdown
        
          id="parties"
          placeholder="Shipper"
          label="name"
          options={parties}
          value={collectionShipper}
          onChange={(event, newValue) => {
              setCollectionShipper(newValue);
          }}
          formError={(formError && formError.errors.shipper_id) ? formError.errors.shipper_id : ''}
      />
</div>
<div className="col-3 mb-4" hidden>
<Dropdown
    id="parties"
    placeholder="Consignee"
    label="name"
    options={parties}
    value={collectionConsignee}
    onChange={(event, newValue) => {
        setCollectionConsignee(newValue);
    }}
    formError={(formError && formError.errors.consignee_id) ? formError.errors.consignee_id : ''}                                        
    />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="description-of-Goods"
                                            placeholder="Description of Goods"
                                            label="Description of Goods"
                                            value={descriptionOfGoods}
                                            onChange={descriptionOfGoodsHandleChange}
                                            formError={(formError && formError.errors.description_of_goods) ? formError.errors.description_of_goods : ''}
                                        />
</div>
<div className="col-2 mb-4">
<InputField
                                            id="gross_Weight"
                                            placeholder="Gross Weight"
                                            label="Gross Weight"
                                            value={weight}
                                            onChange={weightHandleChange}
                                            formError={(formError && formError.errors.weight) ? formError.errors.weight : ''}
                                        />
                                        {/* <button className="btn btn-simple flex-shrink-0 h-100 my-0" disabled>KG </button> */}
</div>
<div className="col-2 mb-4">
  
</div>
<div className="col-2 mb-4">
  
</div>

    </div>

    {formError &&
      formError.errors &&
      formError.errors.cost_currency_id && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_currency_id.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_amount && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_amount.join('. ')}
        </p>
      )}
    {formError &&
      formError.errors &&
      formError.errors.cost_vat && (
        <p className="text-danger small pt-2">
          {formError.errors.cost_vat.join('. ')}
        </p>
      )}

    {formError &&
      formError.errors &&
      formError.errors.have_invoice && (
        <p className="text-danger pt-2">
          {formError.errors.have_invoice}
        </p>
      )}
      
  </Modal.Body>
  <Modal.Footer className="p-4">
    <Button className="mr-3"
      variant="secondary"
      onClick={PreAlertClose}>
      Close
    </Button>
    
    <Button variant="primary" onClick={onPreAlertSubmit}>
      Save  
    </Button>
  </Modal.Footer>
</Modal>

<Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                    >
                      <EnhancedTableHeadDBP
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={collectionDeliveryNotes.length}
                      />
                      <TableBody>
                        {stableSort(collectionDeliveryNotes, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                              
                              <TableCell padding="checkbox" className="selectCheckbox">
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none" >
                                {row.category == 1 ? 'Collection' : row.category == 2 ? 'BookingConfirmation' : row.category == 3 ? 'PreAlert' : ''}</TableCell>
                               
                                <TableCell>{row.collection_date}</TableCell>
                                {/* <TableCell>{row.party_id.name}</TableCell>
                                <TableCell>{row.consignee_id.name}</TableCell>
                                <TableCell>{row.order_number}</TableCell> */}
                                <TableCell>
                                  <button onClick={() => onCollectionPrintPress(row)} className="btn btn-primary btn-sm font-weight-bold mr-3">Print</button>
                                  <button onClick={() => onCollectionDeliveryDeleteHandler(row.id)} className="btn btn-danger btn-sm font-weight-bold mr-2 ">Delete</button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={collectionDeliveryNotes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>

              </Box>
<div className="d-flex justify-content-end border-top-0 pt-4 ">
</div>
</Tab>
         
          </Tabs>
        </Card>
        {/* ******************** modal airwaybill start here **************** */}

        {/* ******************** modal local order start here **************** */}
        <Modal show={showLocalOrder} onHide={handleCloseLocalOrder} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Create a new Local Order </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Row>

              <Col md="3" className="my-4">
                <Dropdown
                  id="parties"
                  placeholder="Shipper"
                  label="name"
                  options={parties}
                  value={shipper}
                  onChange={(event, newValue) => {
                    setShipper(newValue);
                  }}
                  formError={(formError && formError.errors.shipper_id) ? formError.errors.shipper_id : ''}
                />
              </Col>
              <Col md="3" className="my-4">
                <Dropdown
                  id="parties"
                  placeholder="Party"
                  label="name"
                  options={parties}
                  value={partyLocal}
                  onChange={(event, newValue) => {
                    setPartyLocal(newValue);
                  }}
                  formError={(formError && formError.errors.party_id) ? formError.errors.party_id : ''}
                />
              </Col>
              <Col md="3" className="my-4">
                <Dropdown
                  id="parties"
                  placeholder="Consignee"
                  label="name"
                  options={parties}
                  value={consignee}
                  onChange={(event, newValue) => {
                    setConsignee(newValue);
                  }}
                  formError={(formError && formError.errors.consignee_id) ? formError.errors.consignee_id : ''}
                />
              </Col>
              <Col md="3" className="my-4">
                <Dropdown
                  id="projects"
                  placeholder="Project"
                  label="name"
                  options={projects}
                  value={projectLocal}
                  onChange={(event, newValue) => {
                    setProjectLocal(newValue);
                  }}
                  formError={(formError && formError.errors.project_id) ? formError.errors.project_id : ''}
                />

              </Col>
              <Col md="3" className="my-4">
                <InputField
                  id="order-number"
                  placeholder="Order Number"
                  label="Order Number"
                  value={orderNumber}
                  onChange={orderNumberHandleChange}
                  formError={(formError && formError.errors.order_number) ? formError.errors.order_number : ''}
                />
              </Col>

              <Col md="3" className="my-4">
                <InputField
                  id="piece"
                  type="number"
                  placeholder="Piece"
                  label="Piece"
                  value={pcs}
                  onChange={pcsHandleChange}
                  formError={(formError && formError.errors.pcs) ? formError.errors.pcs : ''}
                />
              </Col>

              <Col md="3" className="my-4">
                <InputField
                  id="Weight"
                  type="number"
                  placeholder="Weight"
                  label="Weight"
                  value={weightLocal}
                  onChange={weightLocalHandleChange}
                  formError={(formError && formError.errors.weight) ? formError.errors.weight : ''}
                />
              </Col>
              <Col md="3" className="my-4 form-group d-flex">
                <InputField
                  id="Dimension"
                  placeholder="Dimension"
                  label="Dimension"
                  value={volumeDims}
                  //formError={(formError && formError.errors.dimension) ? formError.errors.dimension : ''}
                />
                <button className="btn btn-simple flex-shrink-0 h-100 my-0" onClick={volumeDimsShow}>DIMS </button>
              </Col>
              <Col md="3" className="my-4">
                <InputField
                  id="delivery-location"
                  placeholder="Delivery Location"
                  label="Delivery Location"
                  value={deliveryLocation}
                  onChange={deliveryLocationHandleChange}
                  formError={(formError && formError.errors.delivery_location) ? formError.errors.delivery_location : ''}
                />
              </Col>
              <Col md="3" className="my-4">
                <Dropdown
                  id="ports"
                  placeholder="Port"
                  label="name"
                  options={ports}
                  value={portLocal}
                  onChange={(event, newValue) => {
                    setPortLocal(newValue);
                  }}
                  formError={(formError && formError.errors.port_id) ? formError.errors.port_id : ''}
                />
              </Col>

            </Row>
            <h6 className="font-weight-bold pb-2 mt-4 border-bottom pb-2 mb-4 mt-4">
              WAREHOUSE DETAILS
            </h6>
            <Row>
              <Col md="3" className="mb-4 form-group d-flex">
                <Dropdown
                  id="locations"
                  placeholder="Location"
                  label="name"
                  options={locations}
                  value={loc}
                  onChange={(event, newValue) => {
                    setLoc(newValue);
                  }}
                  formError={(formError && formError.errors.location) ? formError.errors.location : ''}
                />
              </Col>
              <Col md="3" className="mb-4 form-group d-flex">
                <Dropdown
                  id="racks"
                  placeholder="Rack"
                  label="name"
                  options={racks}
                  value={rack}
                  onChange={(event, newValue) => {
                    setRack(newValue);
                  }}
                  formError={(formError && formError.errors.rack) ? formError.errors.rack : ''}
                />
              </Col>

              <Col md="3" className="mb-4 form-group d-flex">
                <InputDate
                  id="date"
                  placeholder="Packages In Date"
                  label="Packages In Date"
                  value={packageInDate}
                  onChange={packageInDateHandleChange}
                  formError={(formError && formError.errors.packages_in_date) ? formError.errors.packages_in_date : ''}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseLocalOrder}
            >
              Cancel
            </Button>
            <Button variant="primary"
              onClick={handleSubmitLocalOrder}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** Exit Certificate Add Form **************** */}
        <Modal show={showAddEc} onHide={handleCloseAddEc} size="xl">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Add Exit Certificate </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Row>

              <Table className="table-borderless" >
                <tr>
                  <td className="font-weight-bold">Ship's Agent :</td>
                  <td>
                    <Dropdown
                      id="parties"
                      placeholder="Ship's Agent"
                      label="name"
                      options={parties}
                      value={exitAgent}
                      onChange={(event, newValue) => {
                        setExitAgent(newValue);
                      }}
                      formError={(formError && formError.errors.ships_agent) ? formError.errors.ships_agent : ''}
                    />
                  </td>
                  <td className="font-weight-bold">Transit Bill No :</td>
                  <td>
                    <InputField
                      id="transit-bill-no"
                      placeholder="Transit Bill No"
                      label="Transit Bill No"
                      value={exitTransitBillNo}
                      onChange={exitTransitBillNoHandleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Country of Origin :</td>
                  <td>
                    <InputField
                      id="country-of-origin"
                      placeholder="Country of Origin"
                      label="Country of Origin"
                      value={exitOrigin}
                      onChange={exitOriginHandleChange}
                      formError={(formError && formError.errors.origin) ? formError.errors.origin : ''}
                    />

                  </td>
                  <td className="font-weight-bold">Date :</td>
                  <td>
                    <InputDate
                      id="date"
                      placeholder="Date"
                      label="Date"
                      value={exitDate}
                      onChange={exitDateHandleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Airway Bill No :</td>
                  <td>

                    <FormControl variant="outlined" className="w-100">
                      <Autocomplete
                        value={exitAirwayBillNo || null}
                        size="small"
                        onChange={(event, newValue) => {
                          setExitAirwayBillNo(newValue);
                          getAirwaybillById(newValue.id, 'airwayill');
                        }}
                        id="status-box-demo"
                        options={airwaybills}
                        getOptionLabel={(option) => option.prefix.prefix + option.mawb_mcbv_no}
                        renderInput={(params) => <TextField {...params} label="Airway Bill No" />}
                      />

                      {formError &&
                        formError.errors &&
                        formError.errors.airway_bill_no && (
                          <p className="text-danger small pt-2">
                            {formError.errors.airway_bill_no.join('. ')}
                          </p>
                        )}
                    </FormControl>
                  </td>
                  <td className="font-weight-bold">Point of Exit :</td>
                  <td>
                    <Dropdown
                      id="ports"
                      placeholder="Point of Exit"
                      label="name"
                      options={ports}
                      value={exitPoe}
                      onChange={(event, newValue) => {
                        setExitPoe(newValue);
                      }}
                      formError={(formError && formError.errors.point_of_exit) ? formError.errors.point_of_exit : ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Truck Details :</td>
                  <td>
                    <InputField
                      id="transit-bill-no"
                      placeholder="Truck Details"
                      label="Truck Details"
                      value={exitTruckDetails}
                      onChange={exitTruckDetailsHandleChange}
                    />
                  </td>
                </tr>
              </Table>

              <Table className="table-bordered mt-4 mb-0" >
                <thead className="font-weight-bold">
                  <tr>
                    <td> Quantity  </td>
                    <td> Description of Packages </td>
                    <td> Value  </td>
                    <td>  </td>
                  </tr>
                </thead>

                {exitFormValues && exitFormValues.map((element, index) => (
                  <tr>
                    <td> <TextField value={element.quantity ?? ''} name="quantity" onBlur={onBlurExitQuantity} onChange={e => handleExitFieldChange(index, e)} size="small" className="w-100" id="outlined-basic" label="Quantity" variant="outlined" />  </td>
                    <td> <TextField value={element.description ?? ''} name="description" onChange={e => handleExitFieldChange(index, e)} size="small" className="w-100" id="outlined-basic" label="Description" variant="outlined" />   </td>
                    <td> <TextField value={element.value ?? ''} name="value" onChange={e => handleExitFieldChange(index, e)} size="small" className="w-100" id="outlined-basic" label="Value" variant="outlined" /> </td>
                    <td>
                      <DeleteIcon
                        onClick={() => removeExitField(index)}
                        className="text-danger cursor-p">
                      </DeleteIcon>
                    </td>

                  </tr>
                ))}
                <tr className="font-weight-bold">
                  <td > {exitTotalQuantity}  </td>
                  <td >
                    <InputField
                      id="Weight"
                      placeholder="Weight"
                      label="Weight"
                      value={exitWeight}
                      onChange={exitWeightHandleChange}
                    />
                  </td>
                  <td >
                    <FormControl variant="outlined" className="w-100">
                      <InputLabel size="small" id="select1">Destination</InputLabel>
                      <Select
                        size="small"
                        labelId="select1"
                        id="select1"
                        value={exitDestination}
                        onChange={exitDestinationHandleChange}
                        label="Destination"
                      >
                        <MenuItem value=""> <em>None</em> </MenuItem>
                        {ports && ports.map(value => <MenuItem value={value.id}>{value.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <Button className="mr-3"
                      variant="secondary"
                      onClick={() => addExitField()}>
                      Add
                    </Button>
                  </td>
                </tr>
              </Table>

              <div className="mt-5">
                <div className="d-flex justify-content-end border-top-0 pt-4 ">
                </div>
                {formError &&
                  formError.errors && (
                    <p className="text-danger small pt-2">
                      {formError.message}
                    </p>
                  )}
              </div>
            </Row>
            {formError &&
              formError.errors &&
              formError.errors.have_exit_certitificate && (
                <p className="text-danger pt-2">
                  {formError.errors.have_exit_certitificate}
                </p>
              )}
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseAddEc}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={submitExitForm}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete modal start here **************** */}
        <Modal show={showDeleteLocalOrder} onHide={handleCloseDeleteLocalOrder} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete LocalOrder </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessageLocalOrder}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDeleteLocalOrder}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={LocalOrderDeleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete modal start here **************** */}
        <Modal show={showDeleteAirwayBill} onHide={handleCloseDeleteAirwayBill} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete AirwayBill </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessageAirwayBill}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDeleteAirwayBill}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={AirwayBillDeleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete pod modal start here **************** */}
        <Modal show={showDeletePod} onHide={handleCloseDeletePod} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Pod </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessagePod}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDeletePod}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={PodDeleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete exit Cert modal start here **************** */}
        <Modal show={showDeleteExitCert} onHide={handleCloseDeleteExitCert} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Exit Certificate </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessageExitCert}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDeleteExitCert}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={ExitCertDeleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteCollectionDelivery} onHide={handleCloseDeleteCollectionDelivery} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Collection/Booking/Pre Alert </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessageCollectionDelivery}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDeleteCollectionDelivery}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={CollectionDeliveryDeleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete exit Cert modal start here **************** */}
        <Modal show={ShowVolumeDims} onHide={volumeDimsClose} dialogClassName="modal-90w" centered>
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Volume – DIMS  </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Table className="table" striped bordered hover>
              <tr className="font-weight-bold">
                <td>    </td>
                <td> No. Pcs </td>
                <td width="200"> Units </td>
                <td> Length </td>
                <td> Width </td>
                <td> Height </td>
                <td> Volume(CBM) </td>
                <td> Volumetric Weight </td>
              </tr>

              {dimsFormValues.map((element, index) => (
                <tr>
                  <td>  <input type="checkbox" /> </td>
                  <td>  <input type="number" name="piece" className="form-control" value={element.piece} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                  <td>  <select className="custom-select" >
                    <option selected>Centimeters</option>
                  </select>
                  </td>
                  <td>   <input type="number" name="length" className="form-control" value={element.length} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                  <td>   <input type="number" name="width" className="form-control" value={element.width} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                  <td>   <input type="number" name="height" className="form-control" value={element.height} onChange={e => dimsFieldsHandleChange(index, e)} onBlur={e => dimsFieldsFocusOut(index, e)} /> </td>
                  <td>   <input type="number" name="volume" className="form-control" value={element.volume} onChange={() => { }} /> </td>
                  <td>   <input type="number" name="volumetric_weight" className="form-control" value={element.volumetric_weight} onChange={() => { }} /> </td>
                  <td width="50">
                    <DeleteIcon
                      onClick={e => removeDimsField(index, e)}
                      className="text-danger cursor-p">
                    </DeleteIcon>
                  </td>
                </tr>
              ))}

              <tr className="font-weight-bold">
                <td>    </td>
                <td> <input type="text" className="form-control" value={totalDimsPieces} /> </td>
                <td width="200"> </td>
                <td> </td>
                <td>  </td>
                <td>  </td>
                <td> <input type="text" className="form-control" value={volumeDims} /> </td>
                <td> <input type="text" className="form-control" value={volumetricWeightDims} /> </td>
              </tr>

            </Table>
          </Modal.Body>
          <Modal.Footer className="p-4">

            <Button className="mr-3"
              variant="secondary"
              onClick={addDimsField}>
              Add
            </Button>
            <Button variant="primary" onClick={volumeDimsClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
}

export default JobDetails;
